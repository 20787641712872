import { Link, useLocation } from "react-router-dom";
import logo_white from "../../../imgs/logo_white.png";
import "./NavBar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faHome,
  faList,
  faListAlt,
  faMoneyBillAlt,
  faPlus,
  faSignal,
  faTimes,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

export default function NavBar({ authUser }) {
  const { pathname } = useLocation();
  const [ isOpen, setIsOpen ] = useState(true);

  return (
    <div>
      <div className="navResponsiveRow" onClick={() => setIsOpen(!isOpen)}>
        <div>
          <div className="navLogo">
            { isOpen ? <FontAwesomeIcon icon={faTimes} fontSize={55} color="white" /> : <img src={logo_white} alt="logo" /> }
          </div>
        </div>
      </div>

      <nav style={isOpen ? { display: "block"} : { display: "none"} }>
        <div className="navContainer">
          <div className="navLogo">
            <Link to="/">
              <img src={logo_white} alt="logo" />
            </Link>
          </div>
          <ul>
            <Link to="/dashboard">
              <li className={pathname === "/dashboard" ? "active" : ""}>
                <FontAwesomeIcon icon={faHome} color="white" /> Tableau de bord
              </li>
            </Link>
            <Link to="/dashboard/booking/new">
              <li
                className={
                  pathname === "/dashboard/booking/new" ? "active" : ""
                }
              >
                <FontAwesomeIcon icon={faPlus} color="white" /> Nouvelle
                réservation
              </li>
            </Link>
            <Link to="/dashboard/booking/list">
              <li
                className={
                  pathname === "/dashboard/booking/list" ? "active" : ""
                }
              >
                <FontAwesomeIcon icon={faListAlt} color="white" /> Réservations
              </li>
            </Link>
            <Link to="/dashboard/statistiques">
              <li className={pathname === "/dashboard/statistiques" ? "active" : ""}>
                <FontAwesomeIcon icon={faSignal} color="white" /> Statistiques
              </li>
            </Link>
            <Link to="/dashboard/paiements">
              <li
                className={pathname === "/dashboard/paiements" ? "active" : ""}
              >
                <FontAwesomeIcon icon={faMoneyBillAlt} color="white" />{" "}
                Paiements
              </li>
            </Link>
            {authUser.permission === "developper" ? (<hr />) : ""}
            {authUser.permission === "developper" ? (
              <Link to="/dashboard/users">
                <li className={pathname === "/dashboard/users" ? "active" : ""}>
                  <FontAwesomeIcon icon={faUsers} color="white" /> Utilisateurs
                </li>
              </Link>
            ) : ""}
            {authUser.permission === "developper" ? (
              <Link to="/dashboard/events">
                <li className={pathname === "/dashboard/events" ? "active" : ""} >
                  <FontAwesomeIcon icon={faCog} color="white" /> Gestion des événements
                </li>
              </Link>
            ) : ""}
            {authUser.permission === "developper" ? (
              <Link to="/dashboard/logs">
                <li className={pathname === "/dashboard/logs" ? "active" : ""} >
                  <FontAwesomeIcon icon={faList} color="white" /> Logs
                </li>
              </Link>
            ) : ""}
          </ul>
        </div>
      </nav>
    </div>
  );
}
