import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../../../../assets/components/Utils/NavBar";
import { useEffect, useState } from "react";
import { getEvent, updateEvent } from "../../../../helpers/api";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

export default function EditEventScreen({ authUser }) {
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    updateEvent(event).then((res) => {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Modification de l'événement réussie !",
        html: "L'événement a bien été modifié. Vous allez être redirigé vers la liste des événements.",
        showConfirmButton: false,
        timer: 2000,
    }).then(() => {
        navigate("/dashboard/events")
    });
    }).catch((err) => {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: "Echec de la modification de l'événement !",
        html: "Une erreur est survenue lors de la modification de l'événement. Veuillez réessayer.",
        showConfirmButton: false,
        timer: 2000,
    });
      console.error("Error while updating event :", err);
    });
  }

  useEffect(() => {
    getEvent(id).then((res) => {
      setEvent(res.data);
      setLoading(false);
    });
  }, [id]);

  if (loading) {
    return (
      <main>
        <NavBar authUser={authUser} />

        <section className="dashboardContainer">
          <section className="dashboardHeader">
            <div>
              <h1>
                Chargement de l'événement <span>{id}</span> ⚙️
              </h1>
              <p>
                Nous sommes en train de récupérer les données, merci de
                patienter !
              </p>
            </div>
          </section>
        </section>
      </main>
    );
  } else {
    return (
      <main>
        <NavBar authUser={authUser} />

        <section className="dashboardContainer">
          <section className="dashboardHeader">
            <div>
              <h1>Modification d'événement ⚙️</h1>
              <p>
                Vous êtes en train de modifier l'événement :{" "}
                <span>{event.name}</span>
              </p>
            </div>
          </section>

          <section className="dashboardEditEventContainer">
            <form className="dashboardEventEditRow">
              <h2>Informations générales</h2>
              <div className="dashboardNewEventInputRow">
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Nom de l'évenement"
                  variant="standard"
                  color="success"
                  defaultValue={event.name}
                  onChange={(e) => setEvent({ ...event, name: e.target.value })}
                />
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Nombre de places actuellement disponibles"
                  variant="standard"
                  color="success"
                  type="number"
                  defaultValue={event.actual_stock}
                  inputProps={{ min: "0", max: "1000", step: "1" }}
                  onChange={(e) =>
                    setEvent({ ...event, actual_stock: e.target.value })
                  }
                />
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Nombre total de places disponibles"
                  variant="standard"
                  color="success"
                  type="number"
                  defaultValue={event.max_reservation}
                  inputProps={{ min: "0", max: "1000", step: "1" }}
                  onChange={(e) =>
                    setEvent({ ...event, max_reservation: e.target.value })
                  }
                />
              </div>

              <button className="dashboardNewEventButton" onClick={(e) => handleSubmit(e)}>
                Modifier l'évenement
              </button>
              <button className="dashboardDeleteEventButton" onClick={(e) => handleSubmit(e)}>
                Supprimer l'évenement
              </button>
            </form>
            <section className="dashboardEventPrincingRow">
              <h2>Gestion des formules</h2>
              <div className="dashboardEventPricingList">
                <article
                  onClick={() => {
                    navigate(`/dashboard/event/${id}/pricing/new`);
                  }}
                  style={{ backgroundColor: "#A0A0A0", textAlign: "center" }}
                >
                  <h3>Créer une nouvelle formule</h3>
                  <FontAwesomeIcon icon={faPlus} fontSize={36} />
                </article>

                {event.pricing.map((pricing) => {
                  return(
                  <article key={pricing.id} onClick={() => {
                    navigate(`/dashboard/event/${id}/pricing/${pricing.id}`);
                  }}>
                    <div className="dashboardEventDetails">
                      <div className="dashboardEventResume">
                        <h3>{pricing.name}</h3>
                        <div className="dashboardEventState">
                          <p>{pricing.private ? "Cette formule est réservée au comité !" : "Cette formule est accessible à tous !"}</p>
                        </div>
                      </div>
                      <div>
                        <p className="dashboardEventCount">
                          <span>{pricing.price}</span> €
                        </p>
                        <p className="dashboardEventTitle">par personne</p>
                      </div>
                    </div>
                  </article>
                  )
                })}
              </div>
            </section>
          </section>
        </section>
      </main>
    );
  }
}
