import NavBar from "../../../../../assets/components/Utils/NavBar";
import TextField from "@mui/material/TextField";
import moment from "moment";
import "moment/locale/fr";
import React from "react";
import { createPricing } from "../../../../../helpers/api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { FormControlLabel, Switch } from "@mui/material";
moment.locale("fr");

export default function PrincingNewScreen({ authUser }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [pricing, setPricing] = React.useState({
    eventId: id,
    name: "",
    description: "",
    price: 0,
    private: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    createPricing(pricing)
      .then((res) => {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Création de la formule réussie !",
          html: "La formule a bien été créé. Vous allez être redirigé vers la page de l'événements.",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          navigate(`/dashboard/event/${id}`);
        });
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Echec de la création de la formule !",
          html: "Une erreur est survenue lors de la création de la formule. Veuillez réessayer.",
          showConfirmButton: false,
          timer: 2000,
        });
        console.error("Error while creating event :", err);
      });
  };

  return (
    <main>
      <NavBar authUser={authUser} />
      <section className="dashboardContainer">
        <section className="dashboardHeader">
          <div>
            <h1>
              Créer une <span> nouvelle formule</span> 💶
            </h1>
            <p>Ce formulaire permet de créer une nouvelle formule.</p>
          </div>
        </section>

        <section>
          <form>
            <div className="dashboardNewEventInputRow">
              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="standard-basic"
                label="Nom de la formule"
                variant="standard"
                color="success"
                defaultValue={pricing.name}
                onChange={(e) =>
                  setPricing({ ...pricing, name: e.target.value })
                }
              />

              <textarea
                className="dashboardNewEventTextarea"
                rows={5}
                minLength={50}
                placeholder="Description de la formule"
                maxLength={200}
                defaultValue={pricing.description}
                onChange={(e) =>
                  setPricing({ ...pricing, description: e.target.value })
                }
              />

              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="standard-basic"
                label="Prix de la formule"
                variant="standard"
                color="success"
                type="number"
                defaultValue={pricing.price}
                inputProps={{ min: "0", max: "100", step: "3" }}
                onChange={(e) =>
                  setPricing({ ...pricing, price: e.target.value })
                }
              />
            </div>

            <FormControlLabel
              value={pricing.private}
              onChange={(e) =>
                setPricing({ ...pricing, private: !pricing.private })
              }
              sx={{ color: "white" }}
              required
              control={<Switch />}
              color="default"
              label="Est-ce une formule réservée au comité ?"
            />

            <button className="dashboardNewEventButton" onClick={(e) => handleSubmit(e)}>
              Créer la formule
            </button>
          </form>
        </section>
      </section>
    </main>
  );
}
