import NavBar from "../../../../assets/components/Utils/NavBar";
import { Chip, TextField } from "@mui/material";
import { getUsers } from "../../../../helpers/api";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

export default function UsersListScreen({ authUser }) {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);

  const handleSearch = (event, value) => {
    if (value) {
      setUsers(users.filter((user) => user.id === value.id));
    } else {
      getUsers().then((res) => {
        setUsers(res.data);
      });
    }
  };

  useEffect(() => {
    getUsers().then((res) => {
      setUsers(res.data);

      setAutoCompleteValue(
        res.data.map((user) => ({
          label: `${user.first_name + " " + user.last_name}`,
          id: user.id,
        }))
      );
    });
  }, []);

  return (
    <main>
      <NavBar authUser={authUser} />
      <section className="dashboardContainer">
        <section className="dashboardHeaderSearch">
          <div>
            <h1>Liste des utilisateurs 🔥</h1>
            <p>
              Vous avez une liste de <span>{users.length} utilisateurs</span>.
            </p>
          </div>
          <div className="dashboardHeaderSearchInput">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={autoCompleteValue}
              sx={{ width: 300 }}
              onChange={handleSearch}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Rechercher par nom"
                  variant="standard"
                  color="success"
                />
              )}
            />
          </div>
        </section>

        <section className="dashboardTable">
          <article
            onClick={() => {
              navigate("/dashboard/user/new");
            }}
            className="dashboardBookingItemNew"
          >
            <h3>Créer un utilisateur</h3>
            <FontAwesomeIcon icon={faPlus} fontSize={36} />
          </article>
          {users.map((user) => {
            return (
              <article
                key={user.id}
                className="dashboardBookingItem"
                onClick={() => navigate(`/dashboard/user/${user.id}`)}
              >
                <div className="dashboardBookingItemContainer">
                  <h2>
                    {user.first_name} {user.last_name} {" "}
                    {user.status ? (
                      <Chip label="Actif" color="success" />
                    ) : (
                      <Chip label="Non activé" color="warning" />
                    )}
                  </h2>
                  <p>
                    {user.permission === "user" ? (
                      <Chip label="Utilisateur" color="success" />
                    ) : (
                      <Chip label="Administrateur" color="error" />
                    )}
                  </p>
                </div>
              </article>
            );
          })}
        </section>
      </section>
    </main>
  );
}
