import { faUserLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo_color from "../../../../assets/imgs/logo_color.png";
import { Link, useNavigate } from "react-router-dom";

export default function PublicNavBar({ authUser, reserve }) {
  const navigate = useNavigate();

  return (
    <section className="publicNav">
      <div className="publicNavContainer">
        <Link to="/">
          <img
            className="publicNavImg"
            src={logo_color}
            alt="Logo Comité des Fêtes"
          />
        </Link>
        {authUser !== null ? (
          <div>
            <button className="publicNavButton" onClick={() => navigate("/account")}>
              👋 Bonjour, {authUser.first_name} !
            </button>
            {reserve ? (
              <button className="publicNavButton" onClick={() => navigate("/")}>
                Annuler la réservation
              </button>
            ) : (
              <button className="publicNavButton" onClick={() => navigate("/bookings")}>
                Gérer vos réservations
              </button>
            )}
          </div>
        ) : (
          <div>
            <button
              className="publicNavButton"
              onClick={() => navigate("/connexion")}
            >
              <FontAwesomeIcon icon={faUserLock} /> Connexion
            </button>
            <button
              className="publicNavButton"
              onClick={() => window.scrollTo({ top: 700, behavior: "smooth" })}
            >
              Réservez dès maintenant
            </button>
          </div>
        )}
      </div>
    </section>
  );
}
