import NavBar from "../../../../assets/components/Utils/NavBar";
import TextField from "@mui/material/TextField";
import moment from "moment";
import "moment/locale/fr";
import React from "react";
import { createAccountFromAdmin } from "../../../../helpers/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
moment.locale("fr");

export default function NewUserScreen({ authUser }) {
    const navigate = useNavigate();
    const [ user, setUser ] = React.useState({
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        location: "pomps",
        permission: "user",
        newsletter: false,
        status: false
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        createAccountFromAdmin(user).then(res => {
            Swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Création de l'utilisateur réussie !",
                html: "L'utilisateur a bien été créé. Il va recevoir un mail pour créer son mot de passe.",
                showConfirmButton: false,
                timer: 2000,
            }).then(() => {
                navigate("/dashboard/users")
            });
        }).catch(err => {
            Swal.fire({
                toast: true,
                position: "top-end",
                icon: "error",
                title: "Echec de la création de l'utilisateur !",
                html: "Une erreur est survenue lors de la création de l'utilisateur. Veuillez réessayer.",
                showConfirmButton: false,
                timer: 2000,
            });
            console.error("Error while creating user :", err);
        });
    }

  return (
    <main>
      <NavBar authUser={authUser} />
      <section className="dashboardContainer">
        <section className="dashboardHeader">
          <div>
            <h1>
              Créer un <span> nouvel utilisateur</span> 🎉
            </h1>
            <p>Ce formulaire permet de créer un nouvel utilisateur.</p>
          </div>
        </section>

        <section>
          <form>
            <div className="dashboardNewEventInputRow">
              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="first_name"
                label="Prénom"
                variant="standard"
                value={user.first_name}
                onChange={(e) => setUser({ ...user, first_name: e.target.value })}
                />
                <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="last_name"
                label="Nom"
                variant="standard"
                value={user.last_name}
                onChange={(e) => setUser({ ...user, last_name: e.target.value })}
                />
                <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="email"
                label="Email"
                variant="standard"
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                />
                <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="phone"
                label="Téléphone"
                variant="standard"
                value={user.phone}
                onChange={(e) => setUser({ ...user, phone: e.target.value })}
                />
                <FormControl variant="standard" sx={{ width: "100%", marginBottom: "1rem"}}>
                    <InputLabel id="demo-simple-select-standard-label">Commune</InputLabel>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={user.location}
                    onChange={(e) => setUser({ ...user, location: e.target.value })}
                    label="Commune"
                    >
                    <MenuItem value={"pomps"}>Pomps</MenuItem>
                    <MenuItem value={"other"}>Autres</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ width: "100%", marginBottom: "1rem"}}>
                    <InputLabel id="demo-simple-select-standard-label">Permission</InputLabel>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={user.permission}
                    onChange={(e) => setUser({ ...user, permission: e.target.value })}
                    label="Permission"
                    >
                    <MenuItem value={"user"}>Utilisateur</MenuItem>
                    <MenuItem value={"staff"}>Staff</MenuItem>
                    <MenuItem value={"developper"}>Développeur</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ width: "100%", marginBottom: "1rem"}}>
                    <InputLabel id="demo-simple-select-standard-label">Newsletter</InputLabel>
                    <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={user.newsletter}
                    onChange={(e) => setUser({ ...user, newsletter: e.target.value })}
                    label="Newsletter"
                    >
                    <MenuItem value={true}>Oui</MenuItem>
                    <MenuItem value={false}>Non</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <button className="dashboardNewEventButton" onClick={(e) => handleSubmit(e)}>
                Ajouter l'utilisateur
            </button>
          </form>
        </section>
      </section>
    </main>
  );
}
