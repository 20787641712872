import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from "react-router-dom";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { authCheck, axiosRemoveToken, axiosSetToken, getPing } from "./helpers/api";
import WebDownScreen from "./screens/WebDownScreen";
import UnauthorizedScreen from "./screens/dashboard/admin/UnauthorizedScreen";
import AuthContext from "./helpers/AuthContext";
import HomeScreen from "./screens/HomeScreen";
import AuthScreen from "./screens/AuthScreen";
import HomeAdminScreen from "./screens/dashboard/admin/HomeScreen";
import EventsHome from "./screens/dashboard/admin/events";
import NewEventScreen from "./screens/dashboard/admin/events/NewEventScreen";
import EditEventScreen from "./screens/dashboard/admin/events/EditEventScreen";
import PrincingNewScreen from "./screens/dashboard/admin/events/princing/PrincingNewScreen";
import PricingEditScreen from "./screens/dashboard/admin/events/princing/PricingEditScreen";
import BookingListScreen from "./screens/dashboard/admin/booking";
import PublicBookingListScreen from "./screens/dashboard/public/BookingListScreen";
import PublicEditBookingScreen from "./screens/dashboard/public/EditBookingScreen";
import NewBookingScreen from "./screens/dashboard/admin/booking/NewBookingScreen";
import EditBookingScreen from "./screens/dashboard/admin/booking/EditBookinScreen";
import PaymentBookingScreen from "./screens/dashboard/admin/booking/PaymentBookingScreen";
import NewPublicBookingScreen from "./screens/NewBookingScreen";
import WelcomeScreen from "./screens/WelcomeScreen";
import UsersListScreen from "./screens/dashboard/admin/users";
import EditUserScreen from "./screens/dashboard/admin/users/EditUserScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import MentionLegalesScreen from "./screens/legals";
import PrivacyScreen from "./screens/legals/PrivacyScreen";
import CGUScreen from "./screens/legals/CGUScreen";
import AccountScreen from "./screens/dashboard/public/AccountScreen";
import NewUserScreen from "./screens/dashboard/admin/users/NewUserScreen";
import LogScreen from "./screens/dashboard/admin/logs";
import StatsScreen from "./screens/dashboard/admin/stats";
import PaymentsScreen from "./screens/dashboard/admin/paiements";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const LoggedInRoute = ({ children, requiredRole }) => {
  const { authToken, authUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken) {
      // Redirige vers la page de connexion si non authentifié
      navigate("/connexion");
    }
  }, [authToken, navigate]);

  // Si authentifié et qu'il en a la permission, affiche le composant protégé
  return authToken &&
    (authUser.permission === "developper" ||
      !requiredRole ||
      authUser.permission === requiredRole || (authUser.permission === "staff" && requiredRole === "user")) ? (
    React.cloneElement(children, { authUser })
  ) : (
    // Sinon, affiche une page d'erreur
    <UnauthorizedScreen />
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/connexion",
    element: <AuthScreen />,
  },
  {
    path: "/welcome",
    element: <WelcomeScreen />,
  },
  {
    path: "/reset",
    element: <ResetPasswordScreen />,
  },
  {
    path: "/legals",
    element: <MentionLegalesScreen />,
  },
  {
    path: "/privacy",
    element: <PrivacyScreen />,
  },
  {
    path: "/cgu",
    element: <CGUScreen />,
  },
  {
    path: "/event/:id/new",
    element: <NewPublicBookingScreen />,
  },
  {
    path: "/bookings",
    element: (
      <LoggedInRoute requiredRole="user">
        <PublicBookingListScreen />
      </LoggedInRoute>
    ),
  },
  {
    path: "/account",
    element: (
      <LoggedInRoute requiredRole="user">
        <AccountScreen />
      </LoggedInRoute>
    ),
  },
  {
    path: "/booking/:id",
    element: (
      <LoggedInRoute requiredRole="user">
        <PublicEditBookingScreen />
      </LoggedInRoute>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="staff">
          <HomeAdminScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/booking/list",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="staff">
          <BookingListScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/booking/new",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="staff">
          <NewBookingScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/booking/:id",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="staff">
          <EditBookingScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/booking/:id/payment",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="staff">
          <PaymentBookingScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/statistiques",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="staff">
          <StatsScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/paiements",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="staff">
          <PaymentsScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/users",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="developper">
          <UsersListScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/user/new",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="developper">
          <NewUserScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/user/:id",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="developper">
          <EditUserScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/events",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="developper">
          <EventsHome />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/event/new",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="developper">
          <NewEventScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/event/:id",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="developper">
          <EditEventScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/event/:id/pricing/new",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="developper">
          <PrincingNewScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/event/:id/pricing/:pricingId",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="developper">
          <PricingEditScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
  {
    path: "/dashboard/logs",
    element: (
      <ThemeProvider theme={darkTheme}>
        <LoggedInRoute requiredRole="developper">
          <LogScreen />
        </LoggedInRoute>
      </ThemeProvider>
    ),
  },
]);

function AppRoutes() {
  const [authState, setAuthState] = useState({
    authToken: null,
    authUser: null,
  });
  const [stateOfApi, setStateOfApi] = useState(false);

  const auth = (token, user) => {
    axiosSetToken(token);
    setAuthState({ authToken: token, authUser: user });
    window.localStorage.setItem(process.env.REACT_APP_JWT_SECRET, token);
  };

  const logout = () => {
    window.localStorage.removeItem(process.env.REACT_APP_JWT_SECRET);
    axiosRemoveToken();
    setAuthState({ authToken: null, authUser: null });
  };

  const processToken = useCallback((token) => {
    axiosSetToken(token);
    return authCheck()
      .then((checkRes) => {
        auth(token, checkRes.data);
        window.localStorage.setItem(process.env.REACT_APP_JWT_SECRET, token);
      })
      .catch((e) => {
        console.error("Vous avez été déconnecté...");
        window.localStorage.removeItem(process.env.REACT_APP_JWT_SECRET);
      });
  }, []);

  useEffect(() => {
    const token = window.localStorage.getItem(process.env.REACT_APP_JWT_SECRET);
    if (token) {
      processToken(token);
    }
  }, [processToken]);

  useEffect(() => {
    getPing().then(() => {
      setStateOfApi(true);
    }).catch(() => {
      setStateOfApi(false);
    });
  }, [])

  if (!stateOfApi) {
    return (
      <React.StrictMode>
        <ThemeProvider theme={darkTheme}>
          <WebDownScreen />
        </ThemeProvider>
      </React.StrictMode>
    );
  }

  return (
    <React.StrictMode>
      <AuthContext.Provider value={{ ...authState, auth, logout }}>
        <RouterProvider router={router} />
      </AuthContext.Provider>
    </React.StrictMode>
  );
}

export default AppRoutes;
