import "../assets/css/styles.css";
import Swal from "sweetalert2";
import logo_white from "../assets/imgs/logo_white.png";
import { useContext, useEffect, useState } from "react";
import { login, updateUserPassword } from "../helpers/api";
import AuthContext from "../helpers/AuthContext";
import { useNavigate } from "react-router-dom";

export default function AuthScreen() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const { auth } = authContext;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    if (email === "" || password === "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "warning",
        title: "Champs vides",
        html: "Veuillez remplir les champs de connexion.",
        showConfirmButton: false,
        timer: 2000,
      });
    } else {
      let timerInterval;
      Swal.fire({
        toast: true,
        icon: "info",
        showConfirmButton: false,
        title: "Connexion en cours...",
        html: "Nous vérifions vos informations de connexion.",
        timer: 2000,
        position: "top-end",
        timerProgressBar: true,
        willClose: () => {
          clearInterval(timerInterval);
        },
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
          login({
            email: email,
            password: password
          })
            .then((response) => {
              // Si la connexion est réussie
              auth(response.data.token.token, response.data.user);
              Swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Connexion réussie",
                html: "Vous allez être redirigé.",
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
              }).then((successRedirection) => {
                if (successRedirection.dismiss === Swal.DismissReason.timer) {
                  // Redirection vers le tableau de bord
                  navigate("/");
                }
              });
            })
            .catch((error) => {
              // Si la connexion échoue
              console.log(error);
              Swal.fire({
                toast: true,
                position: "top-end",
                icon: "error",
                title: "Echec de la connexion",
                html: "Vos informations de connexion sont incorrectes.",
                showConfirmButton: false,
                timer: 2000,
              });
            });
        }
      });
    }
  };

  const handleResetPassword = () => {
    // Modal de confirmation avant envoi du mail de réinitialisation
    Swal.fire({
      title: "Réinitialisation du mot de passe",
      text: "Un e-mail de réinitialisation vous sera envoyé.",
      icon: "info",
      input: "email",
      inputPlaceholder: "Adresse e-mail",
      inputAttributes: {
        autocapitalize: "off",
        autocorrect: "off",
      },
      showCancelButton: true,
      confirmButtonColor: "#0B963D",
      cancelButtonColor: "#FF5F5F",
      confirmButtonText: "C'est parti !",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        // Envoi de l'e-mail de réinitialisation
        updateUserPassword(result.value).then(() => { 
          Swal.fire({
            title: "E-mail envoyé",
            text: "Dans le cas où l'adresse e-mail est valide, un e-mail de réinitialisation vous a été envoyé.",
            icon: "success",
            showConfirmButton: false,
            timer: 2000,
          });
        })
      }
    });
  };

  useEffect(() => {
    if (authContext.authToken) {
        navigate("/");
    }
  }, [authContext.authToken, navigate]);

  return (
    <main className="authSection">
      <section className="authRow">
        <form onSubmit={(e) => handleLogin(e)}>
          <div>
            <img src={logo_white} alt="Le logo du Comité des Fêtes de Pomps" />
          </div>
          <div className="authInput">
            <input
              type="email"
              autoComplete="email"
              autoFocus
              placeholder="Adresse e-mail"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              autoComplete="current-password"
              placeholder="Mot de passe"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <button type="submit">Connexion</button>
          </div>
          <p style={{ color: "white", cursor: "pointer"}} onClick={() => handleResetPassword()}>Vous avez oublié votre mot de passe ?</p>
        </form>
      </section>
    </main>
  );
}
