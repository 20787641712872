import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function PrivacyStep({ stepUpdate, actualStep }) {
    const handleAccept = () => {
        Swal.fire({
            title: "Nous avons bien pris en compte votre choix.",
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
        }).then(() => {
            stepUpdate(actualStep + 1);
        });
    };

    const handleRefuse = () => {
        Swal.fire({
            title: "Est-ce que vous êtes sûr de vouloir refuser ?",
            html: "Vous ne pourrez pas continuer votre réservation.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#FF5F5F",
            cancelButtonColor: "#0B963D",
            cancelButtonText: "Non, finalement j'accepte",
            confirmButtonText: "Oui, je refuse",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "Nous avons bien pris en compte votre choix.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 500,
                }).then(() => {
                    window.location.href = "/";
                });
            } else {
                Swal.fire({
                    title: "Nous avons bien pris en compte votre choix.",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 500,
                }).then(() => {
                    stepUpdate(actualStep + 1);
                });
            }
        });
    }
    return (
        <section className="publicForm">
        <div className="publicFormHead">
          <h2>
            Pour <span>passer une bonne soirée</span>,
            il est important de <span>la préparer</span> !
          </h2>
          <p>
            Veuillez remplir le formulaire ci-dessous pour finaliser votre
            réservation.
          </p>
        </div>
        <div className="publicFormStepper">
          <h6>
            La sécurité et la confidentialité de vos données sont très importantes
            pour nous, c'est pourquoi nous souhaitons avoir votre autorisation
            de récolter et stocker vos données afin de pouvoir traité votre
            réservation. <br />Vous retrouverez plus d'informations concernant
            l'utilisation de vos données dans notre{" "}
            <Link to="/privacy">Politique de Confidentialité</Link>.
          </h6>
          <div className="publicFormButton">
            <button className="greenButton" onClick={() => handleAccept()}>J'accepte</button>
            <button className="redButton" onClick={() => handleRefuse()}>Je refuse</button>
          </div>
        </div>
      </section>
    );
}