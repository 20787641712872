import { useContext } from "react";
import PublicNavBar from "../../assets/components/Utils/PublicNavBar";
import AuthContext from "../../helpers/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../assets/components/Utils/Footer";

export default function CGUScreen() {
  const { authUser } = useContext(AuthContext);
  const WEBSITE_NAME = "RESERVATION-POMPS.LESFETES.ORG";
  const WEBSITE_RESPONSABLE = "Association Comité des Fêtes de Pomps";

  return (
    <div className="publicSection">
      <section className="publicSectionContainer">
        <PublicNavBar authUser={authUser} />

        <section className="publicHeader">
          <div>
            <h1>Conditions Générales d'Utilisations</h1>
          </div>
        </section>
        <section>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> PRÉAMBULE
          </h2>
          <p>
            Les présentes Conditions Générales d’Utilisateurs de {WEBSITE_NAME}{" "}
            a pour objectif l’encadrement juridique des modalités d’utilisation
            des services du site {WEBSITE_NAME} (ci-après « nous », « notre », «
            nos ») et de l’ensemble de ses services liés. <br />
            Ces conditions générales d’utilisations de {WEBSITE_NAME} est
            considérée accepter par tout Utilisateurs souhaitant accéder au site{" "}
            {WEBSITE_NAME} et ses services liés énumérée ci-dessus. <br />
            Elle constitue le contrat entre {WEBSITE_NAME} et l’Utilisateur.{" "}
            <br />
            <br />
            Toute violation de ces présentes Conditions Générales d’Utilisation
            sera sanctionnée à la hauteur de l’infraction, et il est évident que
            même si une interdiction n’est pas indiquée, le bon sens fait foi.
            Vous pouvez à tout moment nous suggérer l’ajout, la modification ou
            la suppression d’une règle.
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> UTILISATION
          </h2>
          <p>
            L’accès au site {WEBSITE_NAME} est gratuit. L’Utilisateur est
            responsable de son matériel informatique, de sa connexion internet
            et de son utilisation. <br />
            <br />
            L’Utilisateur s’engage à ne pas perturber le bon fonctionnement du
            site {WEBSITE_NAME} et de ses services liés. <br />
            <br />
            L’Utilisateur s’engage à ne pas utiliser le site {WEBSITE_NAME} à
            des fins illégales, commerciales ou publicitaires. <br />
            <br />
            L’Utilisateur s’engage à ne pas utiliser le site {WEBSITE_NAME} pour
            envoyer des messages indésirables ou spam. <br />
            <br />
            L’Utilisateur s’engage à ne pas utiliser le site {WEBSITE_NAME} pour
            collecter des données personnelles, des données de connexion, des
            données de session et des données de navigation. <br />
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> RESPONSABILITÉ DE
            L'UTILISATEUR
          </h2>
          <p>
            L’Utilisateur est responsable de l’utilisation de son compte, de son
            identifiant de connexion et de son mot de passe. <br />
            <br />
            Tout utilisateur est entièrement responsable de l'utilisation, des
            données qu'il publie de nos services et en assume les actions et les
            conséquences causés par l'utilisation de nos services.
            <br />
            Tout utilisateur est responsable de ses comportements, dont le
            comportement diffamatoire, divulgant des informations relatives à la
            vie privée d'un tiers sans autorisation, violant les lois sur le
            droit d'auteurs ou encore violant le droit à la vie privée.
            <br />
            <br />
            L'utilisateur doit prendre les mesures appropriées pour protéger ses
            appareils contre les virus, les accès non autorisés ou les fuites
            d'informations.
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> PROPRIÉTÉ INTELLECTUELLE
            </h2>
            <p>
                Les marques, logos, signes et tout autre contenu du site{" "}
                {WEBSITE_NAME} font l’objet d’une protection par le Code de la
                propriété intellectuelle et plus particulièrement par le droit
                d’auteur. <br />
                <br />
                L’Utilisateur sollicite l’autorisation préalable du site {WEBSITE_NAME}
                pour toute reproduction, publication, copie des différents contenus.
                <br />
                <br />
                L’Utilisateur s’engage à une utilisation des contenus du site{" "}
                {WEBSITE_NAME} dans un cadre strictement privé. <br />
                <br />
                Toute utilisation des contenus à des fins commerciales est strictement
                interdite.
            </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> DROIT D'UTILISATION DE
            VOS DONNÉES
          </h2>
          <p>
            En utilisant nos services et en plubliant volontairement vos
            données, vous accordez à nos services une licence perpétuelle,
            mondiale, libre de droit et irrévocable pour transmettre, stocker,
            modifier et afficher ces données afin d'excecuté les services
            décrits sur nos différents services. Nos services peuvent utiliser,
            modifier les données publiées par un utilisateur pour le bon
            fonctionnement de nos services. <br />
            <br />
            Ces données peuvent également être utilisées à des fins de promotion
            de nos services, vous en serez alors informez avec une demande
            d'accord. <br />
            Toutes les données publiées sur nos services restent la propriété de
            l'utilisateur qui a crée le texte ou/et les images.
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> DÉDOUANEMENT
          </h2>
          <p>
            {WEBSITE_NAME} ne peut être tenu responsable des dommages directs ou
            indirects causés par l'utilisation de nos services. <br />
            <br />n cas de litige ne concernant pas nos services, les
            Utilisateurs doivent le résoudre sous la responsabilité des
            utilisateurs concernées. L'{WEBSITE_RESPONSABLE} ne prendra pas part
            dans ces échanges mais pourra sanctionné tout comportement pouvant
            nuir à son image, à sa seul discrétion.
            <br />
            Nous pouvons a tout moment modifier, interrompre ou résilier nos
            services sans préavis lorsque nous le jugeons nécessaire. Nous ne
            sommes pas responsable des dommage résultant des modifications, de
            l'interruption momentanée ou continue, qu'elle soit de notre volonté
            ou dû à une panne, ou de la résiliation de nos services.
            <br />
            <br />
            Il en va de même que lorsqu'un utilisateur est temporairement
            suspendu ou banni de nos services, tout les dommages causés
            directement ou indirectement ne sont pas de notre responsabilité.
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> JURIDICTION
          </h2>
          <p>
            Les présentes Conditions Générales d’Utilisation sont régies par la
            loi française.
            En cas de litige, les tribunaux français seront seuls compétents.
            <br />
            Si nous décidons de nier une partie de ces Conditions Générales
            d'Utilisation dans un contrat écrit avec un utilisateur, les parties
            restantes de ces Conditions Générales d'Utilisations restent
            pleinement en vigueur.
            <br />
            De plus, si une partie de ces Conditions Générales d'Utilisation est
            jugée invalide par un tribunal compétent, les parties restantes de
            ces Conditions Générales d'Utilisation s'appliqueront pleinement.<br/><br/>
            En cas de litige, les parties s'engagent à rechercher une solution
            amiable avant toute action en justice.
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> MISE À JOUR DES CGU
          </h2>
          <p>
            Les présentes Conditions Générales d’Utilisation peuvent être
            modifiées à tout moment. <br />
            Les Utilisateurs de {WEBSITE_NAME} sont invités à les consulter
            régulièrement. <br />
            Date de dernière mise à jour : 01/09/2024
          </p>
        </section>
        <Footer />
      </section>
    </div>
  );
}
