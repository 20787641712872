export default function ReceiptStep({
  stepUpdate,
  actualStep,
  reservation,
  setReservation,
}) {
  const handleNextStep = (receipt) => {
    setReservation({
      ...reservation,
      receipt: receipt,
    });

    stepUpdate(actualStep + 1);
  };

  return (
    <section className="publicForm">
      <div className="publicFormHead">
        <h2>Un petit point administratif ...</h2>
        <p>
          Les reçus sont envoyés par email et sont une simple preuve de votre
          paiement. Les reçus ne sont pas des factures.
        </p>
      </div>
      <div className="publicFormStepper">
        <h6>Voulez-vous recevoir un reçu pour votre réservation ?</h6>
        <div className="publicFormButton">
          <button className="greenButton" onClick={() => handleNextStep(true)}>
            Oui, j'ai besoin d'un reçu
          </button>
          <button onClick={() => handleNextStep(false)}>
            Je n'ai pas besoin d'un reçu
          </button>
        </div>
      </div>
    </section>
  );
}
