import NavBar from "../../../../../assets/components/Utils/NavBar";
import TextField from "@mui/material/TextField";
import moment from "moment";
import "moment/locale/fr";
import React, { useEffect } from "react";
import { deletePricing, getPricing, updatePricing } from "../../../../../helpers/api";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import { FormControlLabel, Switch } from "@mui/material";
moment.locale("fr");

export default function PrincingEditScreen({ authUser }) {
  const navigate = useNavigate();
  const { id, pricingId } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [pricing, setPricing] = React.useState({
    eventId: pricingId,
    name: "",
    description: "",
    price: 0,
    private: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    updatePricing(pricing)
      .then((res) => {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Modification de la formule réussie !",
          html: "La formule a bien été modifié. Vous allez être redirigé vers la page de l'événements.",
          showConfirmButton: false,
          timer: 2000,
        }).then(() => {
          navigate(`/dashboard/event/${id}`);
        });
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "error",
          title: "Echec de la modification de la formule !",
          html: "Une erreur est survenue lors de la modification de la formule. Veuillez réessayer.",
          showConfirmButton: false,
          timer: 2000,
        });
        console.error("Error while creating event :", err);
      });
  };

  const handleDelete = (e) => {
    deletePricing(pricingId).then((res) => {
        e.preventDefault();
        Swal.fire({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Suppression de la formule réussie !",
            html: "La formule a bien été supprimée. Vous allez être redirigé vers la page de l'événements.",
            showConfirmButton: false,
            timer: 2000,
        }).then(() => {
            navigate(`/dashboard/event/${id}`);
        });
    }).catch((err) => {
        Swal.fire({
            toast: true,
            position: "top-end",
            icon: "error",
            title: "Echec de la suppression de la formule !",
            html: "Une erreur est survenue lors de la suppression de la formule. Veuillez réessayer.",
            showConfirmButton: false,
            timer: 2000,
        });
        console.error("Error while deleting event :", err);
    });
  }

  useEffect(() => {
    getPricing(pricingId).then((res) => {
      setPricing(res.data);
      setLoading(false);
    });
  }, [pricingId]);

  if (loading) {
    return (
      <main>
        <NavBar authUser={authUser} />

        <section className="dashboardContainer">
          <section className="dashboardHeader">
            <div>
              <h1>
                Chargement de l'événement <span>{id}</span> ⚙️
              </h1>
              <p>
                Nous sommes en train de récupérer les données, merci de
                patienter !
              </p>
            </div>
          </section>
        </section>
      </main>
    );
  } else {
    return (
      <main>
        <NavBar authUser={authUser} />
        <section className="dashboardContainer">
          <section className="dashboardHeader">
            <div>
              <h1>
                Modifier une <span> formule</span> 💶
              </h1>
              <p>Ce formulaire permet de modifier une formule.</p>
            </div>
          </section>

          <section>
            <form>
              <div className="dashboardNewEventInputRow">
                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Nom de la formule"
                  variant="standard"
                  color="success"
                  defaultValue={pricing.name}
                  onChange={(e) =>
                    setPricing({ ...pricing, name: e.target.value })
                  }
                />

                <textarea
                  className="dashboardNewEventTextarea"
                  rows={5}
                  minLength={50}
                  placeholder="Description de la formule"
                  maxLength={200}
                  defaultValue={pricing.description}
                  onChange={(e) =>
                    setPricing({ ...pricing, description: e.target.value })
                  }
                />

                <TextField
                  sx={{ width: "100%", marginBottom: "1rem" }}
                  id="standard-basic"
                  label="Prix de la formule"
                  variant="standard"
                  color="success"
                  type="number"
                  defaultValue={pricing.price}
                  inputProps={{ min: "0", max: "100", step: "3" }}
                  onChange={(e) =>
                    setPricing({ ...pricing, price: e.target.value })
                  }
                />
              </div>

              <FormControlLabel
                value={pricing.private}
                onChange={(e) =>
                  setPricing({ ...pricing, private: !pricing.private })
                }
                sx={{ color: "white" }}
                required
                control={<Switch />}
                color="default"
                label="Est-ce une formule réservée au comité ?"
              />

              <button
                className="dashboardNewEventButton"
                onClick={(e) => handleSubmit(e)}
              >
                Modifier la formule
              </button>
              <button className="dashboardDeleteEventButton" onClick={(e) => handleDelete(e)}>
                Supprimer la formule
              </button>
            </form>
          </section>
        </section>
      </main>
    );
  }
}
