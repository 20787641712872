import NavBar from "../../../assets/components/Utils/NavBar";
import "../../../assets/css/dashboard.css";
import moment from 'moment';
import 'moment/locale/fr';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { LineChart } from "@mui/x-charts";
import { useEffect, useState } from "react";
import { getDashboardLiteStats, getDashboardStats } from "../../../helpers/api";

moment.locale('fr');

export default function HomeScreen({ authUser }) {
  const today = moment();
  const [stats, setStats] = useState([]);
  const [liteStats, setLiteStats] = useState({
    usersCount: 0,
    bookingsCount: 0,
    paidBookingsCount: 0,
    unpaidBookingsCount: 0,
  });

  useEffect(() => {
    getDashboardStats().then((response) => {
      // C'est un tableau d'objets avec des clés: "date" et "count"
      const data = response.data;

      // Formater les données pour le LineChart
      const formattedData = data.map(item => ({
        date: item.date,
        count: item.count,
      }));

      setStats(formattedData);
    });

    getDashboardLiteStats().then((response) => {
      setLiteStats(response.data);
      console.log(response.data);
    });
  }, [])

  return (
    <main>
      <NavBar authUser={authUser} />
      <section className="dashboardContainer">
        <section className="dashboardHeader">
          <div>
            <h1>Salut, <span>{authUser.first_name}</span> 👋 !</h1>
            <p>Nous sommes le {moment(today).format("dddd D MMMM YYYY")} et il reste <span>{"quelques jours"}</span> avant les Fêtes de Pomps !</p>
          </div>
        </section>
        <section>
          <div className="dashboardHomeStats">
            <div className="dashboardHomeStatsItem">
              <div className="dashboardHomeStatsHead">
                <h3>Nombre d'inscrits</h3>
              </div>
              <div className="dashboardHomeStatsDetails">
                <p>{liteStats.usersCount}</p>
              </div>
            </div>
            <div className="dashboardHomeStatsItem">
              <div className="dashboardHomeStatsHead">
                <h3>Nombre de réservation</h3>
              </div>
              <div className="dashboardHomeStatsDetails">
                <p>{liteStats.bookingsCount}</p>
              </div>
            </div>
            <div className="dashboardHomeStatsItem">
              <div className="dashboardHomeStatsHead">
                <h3>Chiffre d'affaire</h3>
                <p>Le cumul des recettes effectués par les encaissements des repas.</p>
              </div>
              <div className="dashboardHomeStatsDetails">
                <Gauge
                  width={150}
                  height={150}
                  value={liteStats.paidBookingsCount}
                  valueMax={liteStats.unpaidBookingsCount + liteStats.paidBookingsCount}
                  startAngle={-110}
                  endAngle={110}
                  sx={{
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: 16,
                      transform: 'translate(0px, 0px)',
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                      fill: '#52b202',
                    },
                  }}
                  text={
                    ({ value, valueMax }) => `${value} € / ${valueMax} €`
                  }
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <h2 style={{ color: "white" }}>Réservations des 30 derniers jours</h2>
          {stats.length > 0 ? (
            <LineChart
              className="dashboardHomeChart"
              sx={{ height: 400, width: '100%' }}
              width={window.innerWidth > 1080 ? window.innerWidth - 550 : window.innerWidth - 30}
              height={400}
              xAxis={[{
                data:
                  stats.map(item => item.date),
                valueFormatter: (tick) => moment(tick).format('DD MMM'),
              }]}
              series={[
                {
                  data: stats.map(item => item.count),
                  valueFormatter: (value) => `${value} réservation${value > 1 ? 's' : ''}`,
                  color: "#008831",
                },
              ]}
            />
          ) : (
            <p>Chargement des données...</p>
          )}
        </section>
      </section>
    </main>
  );
}
