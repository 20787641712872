import { useState } from "react";

export default function CommentStep({
  stepUpdate,
  actualStep,
  reservation,
  setReservation,
}) {
  const [comment, setComment] = useState("");

  const handleSubmit = () => {
    let newReservation = {
      ...reservation,
      comment: comment,
    };
    setReservation(newReservation);
    stepUpdate(actualStep + 1);
  };

  return (
    <section className="publicForm">
      <div className="publicFormHead">
        <h2>Un petit commentaire pour la route ?</h2>
        <p>
          Vous avez des demandes particulières, des questions ou des
          informations supplémentaires à nous communiquer ? C'est le moment !
        </p>
      </div>
      <div className="publicFormStepper">
        <h6>
          Vous avez des demandes particulières, des questions ou des
          informations supplémentaires à nous communiquer ? C'est le moment !
        </h6>
        <div className="publicFormInputs">
          <textarea
            placeholder="Votre commentaire"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div>
        <div className="publicFormButton">
          <button className="greenButton" onClick={() => handleSubmit()}>
            Continuer
          </button>
          <button onClick={() => handleSubmit()}>Je n'ai rien a dire</button>
        </div>
      </div>
    </section>
  );
}
