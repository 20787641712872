import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBar from "../../../../assets/components/Utils/NavBar";
import moment from "moment";
import "moment/locale/fr";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import React from "react";
import { getEvents } from "../../../../helpers/api";

moment.locale("fr");


export default function EventsHome({ authUser }) {
  const navigate = useNavigate();
  const [events, setEvents] = React.useState({
    currentEvents: [],
    pastEvents: [],
  });

  React.useEffect(() => {
    getEvents()
      .then((res) => {
        setEvents({
          currentEvents: res.data.currentEvents,
          pastEvents: res.data.pastEvents,
        });
      })
      .catch((err) => {
        console.error("Error while fetching events :", err);
      });
  }, []);

  return (
    <main>
      <NavBar authUser={authUser} />
      <section className="dashboardContainer">
        <section className="dashboardHeader">
          <div>
            <h1>
              Liste des <span>événements</span> 🎉
            </h1>
            <p>
              Nous retrouvons ici toutes la liste des événements passés et à
              venir.
            </p>
          </div>
        </section>

        <section>
          <div className="dashboardEventsRow">
            <h2>Événements à venir</h2>
            <div className="dashboardEventsList">
              <article
                onClick={() => {
                  navigate("/dashboard/event/new");
                }}
                style={{ backgroundColor: "#A0A0A0", textAlign: "center" }}
              >
                <h3>Créer un événement</h3>
                <FontAwesomeIcon icon={faPlus} fontSize={36} />
              </article>
              {events.currentEvents.map((event) => {
                return (
                  <article
                    key={event.id}
                    onClick={() => {
                      navigate(`/dashboard/event/${event.id}`);
                    }}
                  >
                    <div className="dashboardEventDetails">
                      <div className="dashboardEventResume">
                        <h3>{event.name}</h3>
                        <div className="dashboardEventState">
                          {event.status === "created" ? (
                            <p>
                              Ouverture des inscriptions le{" "}
                              {moment(event.date_start)
                                .subtract(1, "month")
                                .subtract(4, "day")
                                .format("LL")}
                            </p>
                          ) : event.status === "open" ? (
                            <p>
                              Fermeture des inscriptions le{" "}
                              {moment(event.date_start)
                                .subtract(4, "day")
                                .format("LL")}
                            </p>
                          ) : event.status === "full" ? (
                            <p>
                              L'événement est pleins ! Plus aucune réservation
                              n'est possible !
                            </p>
                          ) : (
                            <p>
                              Les inscriptions sont fermées, l'événement est en
                              cours !
                            </p>
                          )}
                        </div>
                      </div>
                      <div>
                        <p className="dashboardEventCount">
                          <span>
                            {event.max_reservation - event.actual_stock}
                          </span>{" "}
                          / {event.max_reservation}
                        </p>
                        <p className="dashboardEventTitle">
                          réservation
                          {event.max_reservation - event.actual_stock >= 1
                            ? "s"
                            : ""}
                        </p>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
          </div>
          <div className="dashboardEventsRow">
            <h2>Événements passés</h2>
              <div className="dashboardEventsList">
                {events.pastEvents.map((event) => {
                  return (
                    <article
                      key={event.id}
                      onClick={() => {
                        navigate(`/dashboard/event/${event.id}`);
                      }}
                    >
                      <div className="dashboardEventDetails">
                        <div className="dashboardEventResume">
                          <h3>{event.name}</h3>
                        </div>
                        <div>
                          <div className="dashboardEventState">
                            <p>
                              {moment(event.date_start).format("LL")} -{" "}
                              {moment(event.date_end).format("LL")}
                            </p>
                          </div>
                        </div>
                      </div>
                    </article>
                  );
                })}
              </div>
          </div>
        </section>
      </section>
    </main>
  );
}
