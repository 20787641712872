import { useNavigate } from "react-router-dom";

export default function FinishStep({ reservation }) {
  const navigate = useNavigate();

  const next = () => {
    navigate("/");
  };
  return (
    <section className="publicForm">
      <div className="publicFormHead">
        <h2>
          Et voilà, c'est <span>terminé</span> !
        </h2>
        <p>
          Votre réservation est désormais complète, félicitations à vous ! Nous
          avons hâte de vous retrouver pour une soirée inoubliable.
        </p>
      </div>
      <div className="publicFormStepper">
        <h6>
          Félicitations {reservation.firstName}, votre réservation est désormais
          complète ! Il ne reste plus qu'à vous rendre sur place le jour J pour
          profiter de votre soirée. Un récapitulatif de votre réservation vient
          de vous être envoyé par mail.
        </h6>
        <p>
          Dans le cas ou vous n'avez aucun compte chez nous, un second mail vous
          propose de créer votre compte afin que vous puissiez de vous-même
          modifier votre réservation si besoin avant le grand jour !
        </p>

        <div className="publicFormButton">
          <button className="greenButton" onClick={() => next()}>
            Retourner à l'accueil
          </button>
        </div>
      </div>
    </section>
  );
}
