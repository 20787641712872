import { useContext } from "react";
import PublicNavBar from "../../assets/components/Utils/PublicNavBar";
import AuthContext from "../../helpers/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../assets/components/Utils/Footer";

export default function MentionLegalesScreen() {
  const { authUser } = useContext(AuthContext);
  const WEBSITE_NAME = "RESERVATION-POMPS.LESFETES.ORG";
  const WEBSITE_URL = "https://reservation-pomps.lesfetes.org";
  const WEBSITE_RESPONSABLE = "Association Comité des Fêtes de Pomps";

  return (
    <div className="publicSection">
      <section className="publicSectionContainer">
        <PublicNavBar authUser={authUser} />

        <section className="publicHeader">
          <div>
            <h1>Mentions Légales</h1>
          </div>
        </section>
        <section>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> PRÉAMBULE
          </h2>
          <p>
            LESFETES.ORG et l'intégralité des services liés sont la propriété
            exclusive de la société BRYAAN. <br />
            La société BRYAAN a accordée le droit d'exploitation de
            {WEBSITE_NAME} et de ses services liés à l'{WEBSITE_RESPONSABLE}.{" "}
            <br /> <br />
            Il est interdit d'en faire une copie, reproduction. Tout droit
            réservées à Bryaan. En utilisant nos services, vous acceptez les
            Conditions Générales d'Utilisation, la politique de confidentialité
            et la charte d'utilisation dédié à ces services.
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> RESPONSABILITÉS
          </h2>
          <p>
            La plateforme {WEBSITE_NAME} est les services liés sont développés
            par la société BRYAAN. <br />
            Numéro de téléphone : + 33 7 56 89 00 64 (Prix d'un appel local -
            France)
            <br />
            Adresse mail : contact@bryaan.fr
            <br />
            <br />
            L'{WEBSITE_RESPONSABLE} est responsable de la gestion des événements
            et des réservations. <br />
            Numéro de téléphone : + 33 7 809 809 03 (Prix d'un appel local -
            France)
            <br />
            Adresse mail : comitedesfetespomps@orange.fr
            <br />
            Adresse postale : 1 Place de la Mairie, 64370 Pomps, France
            <br />
            <br />
            La société BRYAAN n'est en aucun cas responsable des événements et
            des réservations. <br />
            Nos services sont hébergés auprès de la société Amazon Web Services
            Inc. dans les serveurs (eu-west-3) de Paris, France. <br />
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> JURIDICTION
          </h2>
          <p>
            Vous acceptez de dédommager et de tenir à couvert BRYAAN de toute
            perte, responsabilité, revendication, requête, dommage, coût et
            dépense, y compris les frais raisonnables d'avocat découlant de ou
            en relation avec votre utilisation du et accès au site, votre
            violation d'une des Conditions, votre violation de tout droit de
            tierce partie, y compris (mais sans limitation) tout droit d'auteur,
            propriété ou droit de confidentialité ou accord tiers, ou tout
            élément de votre contenu ou de vos informations de votre compte ou
            toute autre information que vous publiez ou partagez sur ou via le
            Service. Comme utilisé dans cette section, « vous » désigne
            quiconque accédant à {WEBSITE_NAME} de quelque manière que ce soit.
            Tout acte juridique sera excecuté en applicant les lois en vigeur en
            France.
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> DROITS D'AUTEUR
          </h2>
          <p>
            L'intégralité des services liés à {WEBSITE_NAME} sont la propriété
            exclusive de la société BRYAAN. <br />
            La reprise est conditionnée à l'accord de l'auteur en vertu de
            l'Article L.122-4 de la propriété intellectuelle.
            <br />
            Les images diffusées sur le site {WEBSITE_NAME} sont des images
            libres de droits et d'utilisation ou à la propriété exclusive de l'
            {WEBSITE_RESPONSABLE}. <br /><br/>
            Toute copie, reproduction, modification, publication, transmission,
            dénaturation, totale ou partielle du site ou de son contenu, par
            quelque procédé que ce soit, et sur quelque support que ce soit est
            interdite. <br />
            Toute exploitation non autorisée du site ou de son contenu, des
            informations qui y sont divulguées engagerait la responsabilité de
            l'utilisateur et constituerait une contrefaçon sanctionnée par les
            articles L 335-2 et suivants du Code de la propriété intellectuelle.
            <br /><br/>
            Toutes les données que vous mettez volontairement en ligne sur{" "}
            {WEBSITE_NAME} et ses services sont de votre responsabilité et
            doivent respecter nos Conditions Générales d'Utilisations et le droit d'auteur.{" "}<br/>
            {WEBSITE_NAME} se réserve le droit de supprimer toute donnée qui ne
            respecterait pas nos CGU ou que nous jugeons comme
            innapropriée, et ce sans préavis.
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> MISES À JOUR
          </h2>
          <p>
            Les présentes mentions légales peuvent être modifiées à tout moment
            et sans préavis. Nous vous recommandons de consulter régulièrement
            les présentes mentions légales pour vous tenir informé des
            éventuelles modifications.<br/><br />
            Dernière mise à jour : 01/09/2024
          </p>
        </section>
        <Footer />
      </section>
    </div>
  );
}
