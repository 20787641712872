export default function NewsletterStep({ stepUpdate, actualStep, reservation, setReservation, finish }) {
    const handleSubmit = (value) => {
        let newReservation = {
            ...reservation,
            newsletter: value,
        };
        setReservation(newReservation);
        finish();
        stepUpdate(actualStep + 1);
    }
    return (
        <section className="publicForm">
            <div className="publicFormHead">
                <h2>Allons plus loin ensemble !</h2>
                <p>
                    On ne vous remerciera jamais assez pour votre confiance. Et nous vous offrons la possibilité de rester informé de nos actualités en avant première et de l'actualité de votre comité. Promis, on vous embêtera uniquement quand se sera vraiment important !
                </p>
            </div>
            <div className="publicFormStepper">
                <h6>
                    Pour être tenu informé de nos actualités en avant première et de l'actualité de votre comité, abonnez-vous à notre newsletter !
                </h6>
                <div className="publicFormButton">
                    <button className="greenButton" onClick={() => handleSubmit(true)}>
                        Je souhaite rester informé
                    </button>
                    <button onClick={() => handleSubmit(false)}>
                        Non merci
                    </button>
                </div>
            </div>
        </section>
    );
}