import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";

export default function FormuleStep({
  stepUpdate,
  actualStep,
  reservation,
  setReservation,
  event,
}) {
  const [formule, setFormule] = useState([]);

  const handleSubmit = () => {
    // On vérifie qu'il y a au moins une formule de sélectionnée
    if (formule.filter((item) => item.quantity > 0).length === 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Vous devez sélectionner au moins une formule pour continuer !",
      });
      return;
    }
    // On affiche un récapitulatif de la réservation
    Swal.fire({
      title: "Êtes-vous sûr de vouloir continuer ?",
      html:
        "Voici un petit récapitulatif de votre réservation : <br/>" +
        formule
          .map((item) => {
            return item.quantity > 0
              ? item.quantity +
                  " x " +
                  item.pricingName +
                  " (" +
                  item.pricingPrice +
                  "€)"
              : "";
          })
          .filter((item) => item !== "")
          .join("<br/>") +
        "<br/> Pour un total de " +
        formule.reduce(
          (acc, item) => acc + item.quantity * item.pricingPrice,
          0
        ) +
        " €.",

      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#0B963D",
      confirmButtonText: "Oui, confirmer",
      cancelButtonText: "Annuler",
    }).then((result) => {
      if (result.isConfirmed) {
        let newReservation = {
          ...reservation,
          content: formule,
        };
        setReservation(newReservation);
        stepUpdate(actualStep + 1);
      }
    });
  };

  const handleFormule = (formuleId, quantity) => {
    if (quantity < 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La quantité ne peut pas être négative !",
      });
      return;
    }

    if (quantity > 20) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "La quantité ne peut pas être supérieure à 20 ! Merci de prendre contact avec nous pour une réservation de groupe.",
      });
      return;
    }
    let newFormule = formule.map((item) => {
      if (item.pricingId === formuleId) {
        item.quantity = quantity;
      }
      return item;
    });

    setFormule(newFormule);
  };

  useEffect(() => {
    if (formule.length === 0) {
      let pricingList = [];
      
      event.pricing.map((item) => {
        if(!item.private) {
          pricingList.push({
            pricingId: item.id,
            pricingName: item.name,
            pricingPrice: item.price,
            quantity: 0,
          });
        };
      });

      setFormule(pricingList);
    }
  }, [event, formule.length]);

  if (formule.length === 0) {
    return (
      <section className="publicForm">
        <div className="publicFormHead">
          <h2>
            <span>Seul</span>, en <span>famille</span> ou entre{" "}
            <span>amis</span> ?
          </h2>
          <p>
            Vous pouvez moduler votre réservation en fonction de vos besoins.
            C'est vous qui décidez ! Vous pourrez la modifier par la suite si
            vous le souhaitez.
          </p>
        </div>
        <div className="publicFormStepper">
          <h6>Choix de la formule et de la quantité</h6>
          <div className="publicFormule">
            <p>Loading in progress...</p>
          </div>
          <div className="publicFormButton">
            <button className="greenButton" onClick={() => {}}>
              Confirmer et continuer ..
            </button>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="publicForm">
        <div className="publicFormHead">
          <h2>
            <span>Seul</span>, en <span>famille</span> ou entre{" "}
            <span>amis</span> ?
          </h2>
          <p>
            Vous pouvez moduler votre réservation en fonction de vos besoins.
            C'est vous qui décidez ! Vous pourrez la modifier par la suite si
            vous le souhaitez.
          </p>
        </div>
        <div className="publicFormStepper">
          <h6>Choix de la formule et de la quantité</h6>
          <div className="publicFormule">
            {event?.pricing.map((pricing) => {
              if(pricing.private) {
                return null;
              }
              return (
                <div className="publicFormuleItem">
                  <div className="publicFormuleItemHead">
                    <div className="publicFormuleItemName">
                      <p>{pricing.name}</p>
                      <Chip label={pricing.price + " €"} />
                    </div>
                    <p>{pricing.description}</p>
                  </div>
                  <div className="publicFormuleSelector">
                    <button
                      onClick={() =>
                        handleFormule(
                          pricing.id,
                          formule.filter(
                            (item) => item.pricingId === pricing.id
                          )[0].quantity - 1
                        )
                      }
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={
                        formule.filter(
                          (item) => item.pricingId === pricing.id
                        )[0].quantity
                      }
                      onChange={(e) =>
                        handleFormule(pricing.id, e.target.value)
                      }
                    />
                    <button
                      onClick={() =>
                        handleFormule(
                          pricing.id,
                          formule.filter(
                            (item) => item.pricingId === pricing.id
                          )[0].quantity + 1
                        )
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="publicFormButton">
            <button className="greenButton" onClick={() => handleSubmit()}>
              Confirmer et continuer ..
            </button>
          </div>
        </div>
      </section>
    );
  }
}
