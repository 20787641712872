import NavBar from "../../../../assets/components/Utils/NavBar";
import moment, { min } from 'moment';
import 'moment/locale/fr';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { Chip } from "@mui/material";
import { getLogs } from "../../../../helpers/api";
import { useEffect, useState } from "react";

const columns = [
    { field: 'id', headerName: 'Identifiant', width: 100 },
    { field: 'type', headerName: 'Type', width: 130 },
    { field: 'user', headerName: 'Utilisateur', width: 150 },
    { field: 'message', headerName: 'Message' , width: 700 },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 230,
    },
  ];
  
  const paginationModel = { page: 0, pageSize: 10 };


moment.locale('fr');

export default function LogsScreen({ authUser }) {
    const [ logs, setLogs ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        getLogs().then((logs) => {
            setLogs(logs.data);
            setLoading(false);            
        });
    }, []);
  return (
    <main>
      <NavBar authUser={authUser} />
      <section className="dashboardContainer">
        <section className="dashboardHeader">
          <div>
            <h1>Consultation de l'historique des actions</h1>
            <p>Attention, cette section comporte des données sensible, merci de ne prendre aucune photo !</p>
          </div>
        </section>

        <section className="dashboardContent">
            <Paper sx={{ height: 600, width: '100%' }}>
                <DataGrid
                    rows={logs}
                    columns={columns}
                    initialState={{ pagination: { paginationModel } }}
                    pageSizeOptions={[ 10, 25 ]}
                    sx={{ border: 0 }}
                />
            </Paper>
        </section>
      </section>
    </main>
  );
}
