import { Link } from "react-router-dom";
import logo_color from "../../../assets/imgs/logo_color.png";

export default function UnauthorizedScreen() {
  return (
    <div className="publicSection">
      <section className="publicSectionContainer">
        <section className="publicNav">
          <div className="publicNavContainer">
            <Link to="/">
              <img
                className="publicNavImg"
                src={logo_color}
                alt="Logo Comité des Fêtes"
              />
            </Link>
          </div>
        </section>

        <section className="publicHeader">
          <div>
            <h1>
              Nous sommes sincèrement désolés, mais vous n'êtes pas autorisé à
              accéder à cette page.
            </h1>
            <p>
              Si vous pensez qu'il s'agit d'une erreur, veuillez contacter
              l'administrateur du site.
            </p>
            <div className="publicHeaderButton">
              <button
                onClick={() =>
                  (window.href = "https://www.facebook.com/comitedepomps")
                }
              >
                Notre page Facebook
              </button>
              <button
                onClick={() =>
                  (window.href = "https://www.instagram.com/comitedepomps")
                }
              >
                Notre page instagram
              </button>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
