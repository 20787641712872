import { Link } from "react-router-dom";
import Swal from "sweetalert2";

export default function CguStep({ stepUpdate, actualStep }) {
  const handleAccept = () => {
    Swal.fire({
      title: "Nous avons bien pris en compte votre choix.",
      icon: "success",
      showConfirmButton: false,
      timer: 1000,
    }).then(() => {
      stepUpdate(actualStep + 1);
    });
  };

  const handleRefuse = () => {
    Swal.fire({
      title: "Est-ce que vous êtes sûr de vouloir refuser ?",
      html: "Vous ne pourrez pas continuer votre réservation.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF5F5F",
      cancelButtonColor: "#0B963D",
      cancelButtonText: "Non, finalement j'accepte",
      confirmButtonText: "Oui, je refuse",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Nous avons bien pris en compte votre choix.",
          icon: "success",
          showConfirmButton: false,
          timer: 500,
        }).then(() => {
          window.location.href = "/";
        });
      } else {
        Swal.fire({
          title: "Nous avons bien pris en compte votre choix.",
          icon: "success",
          showConfirmButton: false,
          timer: 500,
        }).then(() => {
          stepUpdate(actualStep + 1);
        });
      }
    });
  };
  return (
    <section className="publicForm">
      <div className="publicFormHead">
        <h2>
          On continue un petit peu de <span>disposition légales</span> avant d'attaquer le vif du sujet.
        </h2>
        <p>
          La loi nous oblige à vous informer de vos droits et devoirs, et nous
            nous devons de vous les rappeler.<br /> Merci de prendre connaissance des
            informations ci-joint.
        </p>
      </div>
      <div className="publicFormStepper">
        <h6>
          Afin de vous apportez la plus grande transparence sur le fonctionne de
          cette plateforme, et apportez les règles élémentaires régissant son
          utilisation, veuillez accepter nos {""}
          <Link to="/cgu">Conditions Générales d'Utilisations</Link>.
        </h6>
        <div className="publicFormButton">
          <button className="greenButton" onClick={() => handleAccept()}>J'accepte</button>
          <button className="redButton" onClick={() => handleRefuse()}>Je refuse</button>
        </div>
      </div>
    </section>
  );
}
