import { useContext, useState } from "react";
import AuthContext from "../../../helpers/AuthContext";
import PublicNavBar from "../../../assets/components/Utils/PublicNavBar";
import { styled } from "@mui/system";
import { Tabs } from "@mui/base/Tabs";
import { TabsList as BaseTabsList } from "@mui/base/TabsList";
import { TabPanel as BaseTabPanel } from "@mui/base/TabPanel";
import { buttonClasses } from "@mui/base/Button";
import { Tab as BaseTab, tabClasses } from "@mui/base/Tab";
import Footer from "../../../assets/components/Utils/Footer";
import { TextField } from "@mui/material";
import { updateUser, updateUserPassword } from "../../../helpers/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function AccountScreen() {
  const navigate = useNavigate();
  const { authUser, logout } = useContext(AuthContext);
  const [user, setUser] = useState(authUser);

  const handleUpdate = () => {
    updateUser(user)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Vos données ont bien été mises à jour !",
          text: "Notre base de données a bien été mis à jour, cependant, il est possible que vous deviez vous reconnecter pour voir les changements.",
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((e) => {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: "Une erreur s'est produite",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handleUpdatePreferences = (itemUpdated, newValue) => {
    setUser({ ...user, [itemUpdated]: newValue });
    updateUser(user)
      .then((res) => {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: "success",
          title: "Vos préférences ont bien été mises à jour !",
          text: `Nous avons bien enregistrer votre choix concernant ${
            itemUpdated === "newsletter"
              ? "la newsletter"
              : itemUpdated === "receipt"
              ? "la réception d'un reçu"
              : "vos données"
          } !`,
          showConfirmButton: false,
          timer: 2000,
        });
      })
      .catch((e) => {
        console.error(e);
        Swal.fire({
          icon: "error",
          title: "Une erreur s'est produite",
          showConfirmButton: false,
          timer: 1500,
        });
      });
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    updateUserPassword(user).then((res) => {
      Swal.fire({
        title: "Un lien de réinitialisation de mot de passe a été envoyé à votre adresse email.",
        text: "Le compte a temporairement été désactivé. Vous devrez réinitialiser votre mot de passe pour vous reconnecter. Vous allez être redirigé vers la page de connexion.",
        icon: "success",
        timer: 5000,
        showConfirmButton: false,
      }).then((result) => {
        if (result.dismiss === Swal.DismissReason.timer) {
            logout();
        }
      });
    });
  };

  const handleNotAvailable = () => {
    Swal.fire({
        title: "Cette fonctionnalité n'est pas encore disponible 😭",
        text: "Nous travaillons dur pour vous offrir une expérience de qualité. Nous vous invitons a revenir plus tard.",
        icon: "error",
        confirmButtonText: "Fermer",
      });
}

  return (
    <div className="publicSection">
      <section className="publicSectionContainer">
        <PublicNavBar authUser={authUser} />
        <section className="publicHeader">
          <div>
            <h1>Gestion de votre compte</h1>
            <p>
              En quelques clics, on apporte les corrections dont vous avez
              besoins !
            </p>
            <div className="publicHeaderButton">
              {authUser.permission !== "user" ? (
                  <button onClick={() => navigate("/dashboard")}>Administration</button>
                ) : null }
            <button onClick={() => logout()}>Déconnexion</button>
            </div>
          </div>
        </section>

        <Tabs defaultValue={0}>
          <TabsList>
            <Tab value={0}>Informations personnelles</Tab>
            <Tab value={1}>Préférences personnelles</Tab>
            <Tab value={2}>Actions</Tab>
          </TabsList>
          <TabPanel value={0}>
            <h2>Informations personnelles</h2>
            <p>Vous pouvez mettre à jour vos informations personnelles ici.</p>

            <TextField
              sx={{ width: "100%", marginBottom: "1rem", bgColor: "#000" }}
              id="standard-basic"
              label="Identifiant"
              disabled
              variant="standard"
              color="success"
              defaultValue={authUser.id}
            />
            <TextField
              sx={{ width: "100%", marginBottom: "1rem" }}
              id="standard-basic"
              label="Prénom"
              variant="standard"
              color="success"
              type="text"
              defaultValue={user.first_name}
              onChange={(e) => setUser({ ...user, first_name: e.target.value })}
            />

            <TextField
              sx={{ width: "100%", marginBottom: "1rem" }}
              id="standard-basic"
              label="Nom de famille"
              variant="standard"
              color="success"
              type="text"
              defaultValue={user.last_name}
              onChange={(e) => setUser({ ...user, last_name: e.target.value })}
            />

            <TextField
              sx={{ width: "100%", marginBottom: "1rem" }}
              id="standard-basic"
              label="Email"
              variant="standard"
              color="success"
              type="email"
              defaultValue={user.email}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />

            <TextField
              sx={{ width: "100%", marginBottom: "1rem" }}
              id="standard-basic"
              label="Téléphone"
              variant="standard"
              color="success"
              type="phone"
              defaultValue={user.phone}
              onChange={(e) => setUser({ ...user, phone: e.target.value })}
            />
            <div className="publicFormButton">
              <button className="greenButton" onClick={() => handleUpdate()}>
                Enregistrer les modifications
              </button>
            </div>
          </TabPanel>
          <TabPanel value={1} sx={{ width: "100%" }}>
            <h2>Préférences personnelles</h2>
            <p>Vous pouvez mettre à jour vos préférences personnelles ici.</p>

            <div className="accountPreferenceItem">
              <h3>Acceptez-vous de recevoir une newsletter de notre part ?</h3>
              <div className="publicPreferenceButton">
                <button
                  onClick={() => handleUpdatePreferences("newsletter", true)}
                  className={user.newsletter ? "greenButton" : ""}
                >
                  Oui
                </button>
                <button
                  onClick={() => handleUpdatePreferences("newsletter", false)}
                  className={user.newsletter ? "" : "greenButton"}
                >
                  Non
                </button>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={2}>
            <h2>Actions</h2>
            <p>Vous pouvez effectuer des actions sur votre compte ici.</p>

            <div className="accountPreferenceItem">
              <h3>Vous souhaitez modifier votre mot de passe ?</h3>
              <div className="publicPreferenceButton">
                <button
                  onClick={(e) => handleUpdatePassword(e)}
                  className="redButton"
                >
                  Modifier mon mot de passe
                </button>
              </div>
            </div>

            <div className="accountPreferenceItem">
              <h3>RGPD - Je demande a recevoir une copie de l'ensemble des informations détenus mon concernant.</h3>
              <div className="publicPreferenceButton">
                <button
                  onClick={() => handleNotAvailable()}
                  className="greenButton"
                >
                  Recevoir mes données
                </button>
              </div>
            </div>

            <div className="accountPreferenceItem">
              <h3>RGPD - Je demande ce que mes données soient supprimées.</h3>
              <div className="publicPreferenceButton">
                <button
                  onClick={() => handleNotAvailable()}
                  className="redButton"
                >
                  Supprimer mon compte
                </button>
              </div>
            </div>
          </TabPanel>
        </Tabs>

        <Footer />
      </section>
    </div>
  );
}

const green = {
  50: "#F0FFF4",
  100: "#C6F6D5",
  200: "#9AE6B4",
  300: "#68D391",
  400: "#48BB78",
  500: "#38A169",
  600: "#2F855A",
  700: "#276749",
  800: "#22543D",
  900: "#1C4532",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const Tab = styled(BaseTab)`
  font-family: "IBM Plex Sans", sans-serif;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 600;
  background-color: transparent;
  width: 100%;
  padding: 10px 12px;
  margin: 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${green[400]};
  }

  &:focus {
    color: #fff;
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${green[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)(
  ({ theme }) => `
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    padding: 20px 12px;
    background: #fff;
    border-radius: 12px;
    margin-top: 50px;
    `
);

const TabsList = styled(BaseTabsList)(
  ({ theme }) => `
    min-width: 400px;
    background-color: ${green[500]};
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0px 4px 30px ${
      theme.palette.mode === "dark" ? grey[900] : grey[200]
    };
    `
);
