import { useEffect, useState } from "react";
import NavBar from "../../../../assets/components/Utils/NavBar";
import { useNavigate, useParams } from "react-router-dom";
import { createPayment, getBooking } from "../../../../helpers/api";
import { Chip } from "@mui/material";
import Swal from "sweetalert2";

export default function PaymentBookingScreen({ authUser }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [booking, setBooking] = useState([]);
  const [loading, setLoading] = useState(true);
  const [payment, setPayment] = useState({
    reservationId: id,
    collectorId: authUser.id,
    content: [],
    montant: 0,
    paymentMethod: "cb",
    status: "in_progress",
    comment: "",
    transactionId: "",
  });

  const handleUpdateBookingContent = (value, index) => {
    if (value < 0) {
      Swal.fire({
        icon: "error",
        title: "Oups !",
        text: "La quantité ne peut pas être inférieure à 0 !",
        showConfirmButton: false,
        timer: 3000,
      });
    } else if (
      value > booking.content.find((item) => item.pricingId === index).quantity
    ) {
      Swal.fire({
        icon: "error",
        title: "Oups !",
        text: "La quantité ne peut pas être supérieure à celle de la réservation !",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      // Créez une copie profonde des données de payment.content
      let newContent = payment.content.map((item) => {
        if (item.pricingId === index) {
          return { ...item, quantity: value };
        }
        return item;
      });

      setPayment({
        ...payment,
        content: newContent,
        montant: newContent.reduce(
          (acc, item) => acc + item.quantity * item.pricingPrice,
          0
        ),
      });
    }
  };

  const handlePayment = (fruit) => {
    Swal.fire({
      title: "Veuillez confirmer le moyen paiement !",
      html: `<strong>Merci d'utiliser l'application de caisse pour enregistré le paiement !</strong><br><p>Le contenu de la réservation est le suivant : ${payment.content.map(
        (formule, index) => {
          return `<br><strong>x${formule.quantity} ${formule.pricingName}(s)</strong>`;
        }
      )}.`,
      input: "select",
      inputOptions: {
        cb: "Carte bancaire",
        cash: "Espèces",
        check: "Chèque",
        free: "Offert par le comité",
      },
      inputPlaceholder: "Selectionner un moyen de paiement",
      showCancelButton: true,
      cancelButtonText: "Echec du paiement",
      cancelButtonColor: "#FF5F5F",
      confirmButtonText: `Confirmer le paiement de ${payment.montant} €`,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== "") {
            setPayment({ ...payment, paymentMethod: value });
            if (booking.receipt) {
              Swal.fire({
                title: "Veuillez confirmer le numéro de transaction !",
                text: "Le client ayant demandé un reçu, merci de renseigner le numéro de transaction.",
                input: "text",
                inputPlaceholder: "Numéro de transaction",
                showCancelButton: true,
                cancelButtonText: "Annuler le réglement",
                cancelButtonColor: "#FF5F5F",
                confirmButtonText: `Enregistrer le paiement de ${payment.montant} €`,
                inputValidator: (value) => {
                  return new Promise((resolve) => {
                    if (value !== "") {
                      setPayment({ ...payment, transactionId: value });
                      createPayment(payment).then((res) => {
                        Swal.fire({
                          icon: "success",
                          title: "Paiement enregistré !",
                          text: `Le paiement de ${payment.montant} € a bien été enregistré !`,
                          showConfirmButton: false,
                          timer: 3000,
                        });
                        navigate(`/dashboard/booking/${id}`);
                      });
                    } else {
                      resolve("Merci de renseigner le numéro de transaction !");
                    }
                  });
                },
              });
            } else {
              setPayment({ ...payment, paymentMethod: value });
              createPayment(payment).then((res) => {
                Swal.fire({
                  icon: "success",
                  title: "Paiement enregistré !",
                  text: `Le paiement de ${payment.montant} € a bien été enregistré !`,
                  showConfirmButton: false,
                  timer: 3000,
                });
                navigate(`/dashboard/booking/${id}`);
              });
            }
            // resolve();
          } else {
            resolve("Merci de choisir un moyen de paiement !");
          }
        });
      },
    });
  };

  useEffect(() => {
    getBooking(id).then((res) => {
      setBooking(res.data);
      if (res.data.status === "partial_paid") {
        // Je retire les formules déjà payées

        setPayment({
          ...payment,
          content: res.data.content.map((item) => ({
            pricingId: item.pricingId,
            pricingName: item.pricingName,
            pricingPrice: item.pricingPrice,
            quantity:
              item.quantity -
              res.data.payments.reduce((total, payment) => {
                const paymentQuantity = payment.content
                  .filter(
                    (paymentContent) =>
                      paymentContent.pricingId === item.pricingId
                  )
                  .reduce(
                    (acc, paymentContent) => acc + paymentContent.quantity,
                    0
                  );
                return total + paymentQuantity;
              }, 0),
          })),
        });
      } else {
        setPayment({
          ...payment,
          content: res.data.content.map((item) => ({
            pricingId: item.pricingId,
            pricingName: item.pricingName,
            pricingPrice: item.pricingPrice,
            quantity: item.quantity,
          })),
        });
      }
      setLoading(false);
    });
  }, [id]);
  if (loading) {
    return (
      <main>
        <NavBar authUser={authUser} />
        <section className="dashboardContainer">
          <section className="dashboardHeaderSearch">
            <div>
              <h1>Enregistrement d'un paiement 💶</h1>
            </div>
          </section>
          <section className="dashboardTable">
            <div className="dashboardBookingItemContainer">
              <h2>Chargement en cours...</h2>
            </div>
          </section>
        </section>
      </main>
    );
  } else {
    return (
      <main>
        <NavBar authUser={authUser} />
        <section className="dashboardContainer">
          <section className="dashboardHeaderSearch">
            <div>
              <h1>Enregistrement d'un paiement 💶</h1>
              <p>
                Tu peux décidé d'un paiement partiel ou total en modifiant la
                quantité ici :D
              </p>
            </div>
          </section>

          <section className="dashboardTable">
            {payment.content.map((item, index) => {
              return (
                <article className="dashboardBookingItem" key={index}>
                  <div className="dashboardBookingItemContainer">
                    <h2>
                      📝 {item.pricingName}{" "}
                      <Chip label={`${item.quantity * item.pricingPrice} €`} />{" "}
                      <Chip
                      color="warning"
                        label={`${booking.payments.reduce((total, payment) => {
                          const paymentQuantity = payment.content
                            .filter(
                              (paymentContent) =>
                                paymentContent.pricingId === item.pricingId
                            )
                            .reduce(
                              (acc, paymentContent) =>
                                acc + paymentContent.pricingPrice * paymentContent.quantity,
                              0
                            );
                          return total + paymentQuantity;
                        }, 0)} € ont automatiquement été déduits !`}
                      />
                    </h2>
                    <div className="dashboardBookingItemEditQuantity">
                      <button
                        onClick={() =>
                          handleUpdateBookingContent(
                            item.quantity - 1,
                            item.pricingId
                          )
                        }
                      >
                        -
                      </button>
                      <input
                        onChange={(e) =>
                          handleUpdateBookingContent(
                            e.target.value,
                            item.pricingId
                          )
                        }
                        type="number"
                        value={item.quantity}
                        min={0}
                        max={item.quantity}
                        step={1}
                      />
                      <button
                        onClick={() =>
                          handleUpdateBookingContent(
                            item.quantity + 1,
                            item.pricingId
                          )
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                </article>
              );
            })}
            <article className="dashboardBookingItem">
              <div className="dashboardBookingItemContainer">
                <h2>📦 Total de la réservation</h2>
                <h2>
                  {payment.content.reduce(
                    (acc, item) => acc + item.quantity * item.pricingPrice,
                    0
                  )}{" "}
                  €
                </h2>
              </div>
            </article>
          </section>
          <section className="dashboardForm">
            <button onClick={() => handlePayment()}>
              Enregistrer le paiement
            </button>
          </section>
        </section>
      </main>
    );
  }
}
