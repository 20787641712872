import NavBar from "../../../../assets/components/Utils/NavBar";
import TextField from "@mui/material/TextField";
import moment from "moment";
import "moment/locale/fr";
import React from "react";
import { createEvent } from "../../../../helpers/api";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
moment.locale("fr");

export default function NewEventScreen({ authUser }) {
    const navigate = useNavigate();
    const [ event, setEvent ] = React.useState({
        authorId: authUser.id,
        name: "Fêtes de Pomps",
        startDate: moment().format("YYYY-MM-DDTHH:mm"),
        endDate: moment().add(1, "day").format("YYYY-MM-DDTHH:mm"),
        availablePlaces: 250
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        createEvent(event).then(res => {
            Swal.fire({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Création de l'événement réussie !",
                html: "L'événement a bien été créé. Vous allez être redirigé vers la liste des événements.",
                showConfirmButton: false,
                timer: 2000,
            }).then(() => {
                navigate("/dashboard/events")
            });
        }).catch(err => {
            Swal.fire({
                toast: true,
                position: "top-end",
                icon: "error",
                title: "Echec de la création de l'événement !",
                html: "Une erreur est survenue lors de la création de l'événement. Veuillez réessayer.",
                showConfirmButton: false,
                timer: 2000,
            });
            console.error("Error while creating event :", err);
        });
    }

  return (
    <main>
      <NavBar authUser={authUser} />
      <section className="dashboardContainer">
        <section className="dashboardHeader">
          <div>
            <h1>
              Créer un <span> nouvel événement</span> 🎉
            </h1>
            <p>Ce formulaire permet de créer un nouvelle événement.</p>
          </div>
        </section>

        <section>
          <form>
            <div className="dashboardNewEventInputRow">
              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="standard-basic"
                label="Nom de l'évenement"
                variant="standard"
                color="success"
                defaultValue={event.name}
                onChange={(e) => setEvent({ ...event, name: e.target.value })}
              />
              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="standard-basic"
                label="Date de début"
                variant="standard"
                color="success"
                defaultValue={event.startDate}
                type="datetime-local"
                onChange={(e) => setEvent({ ...event, startDate: e.target.value })}
              />

              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="standard-basic"
                label="Date de fin"
                variant="standard"
                color="success"
                defaultValue={event.endDate}
                type="datetime-local"
                onChange={(e) => setEvent({ ...event, endDate: e.target.value })}
              />

              <TextField
                sx={{ width: "100%", marginBottom: "1rem" }}
                id="standard-basic"
                label="Nombre de places disponibles"
                variant="standard"
                color="success"
                type="number"
                defaultValue={event.availablePlaces}
                inputProps={{ min: "0", max: "1000", step: "1" }}
                onChange={(e) => setEvent({ ...event, availablePlaces: e.target.value })}
              />
            </div>

            <button className="dashboardNewEventButton" onClick={(e) => handleSubmit(e)}>
                Ajouter l'évenement
            </button>
          </form>
        </section>
      </section>
    </main>
  );
}
