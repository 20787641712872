import { useNavigate, useParams } from "react-router-dom";
import NavBar from "../../../../assets/components/Utils/NavBar";
import { useEffect, useState } from "react";
import { getBooking, updateBooking } from "../../../../helpers/api";
import { Chip } from "@mui/material";
import moment from "moment";
import "moment/locale/fr";
import Swal from "sweetalert2";
moment.locale("fr");

export default function EditBookingScreen({ authUser }) {
  const { id } = useParams();
  const [booking, setBooking] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();  

  const handleCreateUser = () => {
    Swal.fire({
      title: "Modifier la méthode de paiement",
      input: "select",
      inputOptions: {
        cb: "Carte bancaire",
        cheque: "Chèque",
        espece: "Espèces",
      },
      inputPlaceholder: "Sélectionnez une méthode de paiement",
      confirmButtonText: "Valider",
      confirmButtonColor: "#4caf50",
      cancelButtonText: "Annuler",
      showCancelButton: true,
      preConfirm: async (value) => {
        try {
          await updateBooking({
            id: booking.id,
            payment_method: value,
          }).then((res) => {
            return res.data;
          });
        } catch (error) {
          Swal.showValidationMessage(`
            Request failed: ${error}
          `);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: "Perfect !",
          text: "La modification a bien été prise en compte !",
          showConfirmButton: false,
          timer: 1000,
        });

        setBooking({
          ...booking,
          payment_method: result.value,
        });
      }
    });
  };

  const handleUpdateBookingContent = (value, index) => {
    if (value < 0) {
      Swal.fire({
        icon: "error",
        title: "Oups !",
        text: "La quantité ne peut pas être inférieure à 0 !",
        showConfirmButton: false,
        timer: 3000,
      });
    } else {
      let newContent = booking.content;
      newContent = newContent.map((item) => {
        if (item.pricingId === index) {
          item.quantity = value;
        }
        return item;
      });
      updateBooking({
        id: booking.id,
        content: newContent,
      }).then((res) => {
        Swal.fire({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          title: "La quantité a bien été modifiée !",
        });
        setBooking({
          ...booking,
          content: newContent,
        });
      });
    }
  };

  const handleCancelBooking = () => {
    Swal.fire({
      title: "Vous êtes sûr ?",
      text: "Vous voulez vraiment annuler cette réservation ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF5F5F",
      confirmButtonText: "Oui, annuler la réservation !",
    }).then((result) => {
      if (result.isConfirmed) {
        updateBooking({
          id: booking.id,
          status: "canceled",
        }).then((res) => {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: "La réservation a bien été annulée !",
          });
          setBooking({
            ...booking,
            status: "canceled",
          });
        });
      }
    });
  };

  const handleOpenBooking = () => {
    Swal.fire({
      title: "Vous êtes sûr ?",
      text: "Vous voulez vraiment rouvrir cette réservation ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#FF5F5F",
      confirmButtonText: "Oui, rouvrir la réservation !",
    }).then((result) => {
      if (result.isConfirmed) {
        updateBooking({
          id: booking.id,
          status: "not_paid",
        }).then((res) => {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            title: "La réservation a bien été rouverte !",
          });
          setBooking({
            ...booking,
            status: "not_paid",
          });
        });
      }
    });
  };

  useEffect(() => {
    getBooking(id).then((res) => {
      setBooking(res.data);     
      setLoading(false);
    });
  }, [id]);

  if (loading) {
    return (
      <main>
        <NavBar authUser={authUser} />
        <section className="dashboardContainer">
          <section className="dashboardHeader">
            <div>
              <h1>Chargement de la réservation 🤙</h1>
              <p>Nous chargeons les informations de la réservation...</p>
            </div>
          </section>
        </section>
      </main>
    );
  } else {
    return (
      <main>
        <NavBar authUser={authUser} />
        <section className="dashboardContainer">
          <section className="dashboardHeaderSearch">
            <div>
              <h1>Finalisation de la réservation 🤙</h1>
              <p>
                Cette réservation a été établi le{" "}
                <span>{moment(booking.created_at).format("DD MMMM YYYY")}</span>{" "}
                {booking.type === "barricot"
                  ? "pendant le barricot"
                  : booking.type === "web"
                  ? "depuis le formulaire en ligne"
                  : booking.type === "discussion"
                  ? "pendant une discussion avec un membre du comité"
                  : booking.type === "mail"
                  ? "par mail"
                  : booking.type === "phone"
                  ? "par téléphone"
                  : "mais nous ne savons pas comment il a réussi à réserver.."}{" "}
                pour {booking.event?.name}.
              </p>
            </div>
            <div className="dashboardHeaderSearchInput">
              <Chip label={`🎟️ ${booking.name}`} color="success" />
            </div>
          </section>
          <section className="dashboardTable">
            {booking.status === "canceled" ? (
              <article
                className="dashboardBookingItem"
                style={{ backgroundColor: "#FF5F5F" }}
              >
                <div className="dashboardBookingItemContainer">
                  <h2>😱 Cette réservation a été annulée !</h2>
                </div>
              </article>
            ) : booking.status === "partial_paid" ? (
              <article
                className="dashboardBookingItem"
                style={{ backgroundColor: "#FFC107" }}
              >
                <div className="dashboardBookingItemContainer">
                  <h2>🤔 Cette réservation est partiellement payée !</h2>
                </div>
              </article>
            ) : (
              ""
            )}
          </section>
          <section className="dashboardTable">
            <article
              className="dashboardBookingItem"
              onClick={handleCreateUser}
            >
              <div className="dashboardBookingItemContainer">
                <h2>
                  {booking.user?.first_name
                    ? `${booking.user.last_name} ${booking.user.first_name}`
                    : booking.name}
                </h2>
                <p>
                  <span>
                    📍{" "}
                    {booking.user?.email
                      ? booking.user?.email
                      : "Aucune adresse mail renseignée"}
                  </span>
                </p>
                <p>
                  <span>
                    📞{" "}
                    {booking.user?.phone
                      ? booking.user?.phone
                      : "Aucun téléphone renseigné"}
                  </span>
                </p>
                <p>
                  <Chip
                    label={`💳 ${
                      booking.payment_method === "cb"
                        ? "Carte Bancaire"
                        : booking.payment_method === "cheque"
                        ? "Chèque"
                        : "Espèces"
                    }`}
                  />
                </p>
              </div>
            </article>
          </section>
          <section className="dashboardTable">
            {booking.content.map((item, index) => {
              return (
                <article className="dashboardBookingItem" key={index}>
                  <div className="dashboardBookingItemContainer">
                    <h2>
                      📝 {item.pricingName}{" "}
                      <Chip label={`${item.quantity * item.pricingPrice} €`} />{" "}
                      { booking.payments.length > 0 ? (
                        <Chip
                      color="warning"
                        label={`${booking.payments.reduce((total, payment) => {
                          const paymentQuantity = payment.content
                            .filter(
                              (paymentContent) =>
                                paymentContent.pricingId === item.pricingId
                            )
                            .reduce(
                              (acc, paymentContent) =>
                                acc + paymentContent.quantity ,
                              0
                            );
                          return total + paymentQuantity;
                        }, 0)} déjà payé(s) !`}
                      />
                      ) : ""}
                    </h2>
                    {booking.status === "paid" ? (
                      <p>
                        <span>
                          La commande est déjà payée.
                        </span>
                      </p>
                    ) : (

                      <div className="dashboardBookingItemEditQuantity">
                        <button
                          onClick={() =>
                            handleUpdateBookingContent(
                              item.quantity - 1,
                              item.pricingId
                            )
                          }
                        >
                          -
                        </button>
                        <input
                          onChange={(e) =>
                            handleUpdateBookingContent(
                              e.target.value,
                              item.pricingId
                            )
                          }
                          type="number"
                          value={item.quantity}
                          min={0}
                          max={50}
                          step={1}
                        />
                        <button
                          onClick={() =>
                            handleUpdateBookingContent(
                              item.quantity + 1,
                              item.pricingId
                            )
                          }
                        >
                          +
                        </button>
                      </div>
                    )}
                  </div>
                </article>
              );
            })}
            <article className="dashboardBookingItem">
              <div className="dashboardBookingItemContainer">
                <h2>
                  📦 Reste à payer {" "}
                  {booking.payments.length > 0 ? (
                  <Chip
                    color={booking.status === "paid" ? "success" : "warning"}
                    label={`${booking.payments.reduce((total, payment) => {
                      return (
                        total +
                        payment.content.reduce(
                          (acc, item) =>
                            acc + item.quantity * item.pricingPrice,
                          0
                        )
                      );
                    }, 0)} € ${booking.status === "paid" ? "ont été réglés" : "ont été retirés car déjà payé"} !`}
                  />
                  ) : ""}
                </h2>
                <h2>
                  {booking.content.reduce((acc, item) => {
                    return acc + item.quantity * item.pricingPrice;
                  }, 0) -
                    booking.payments.reduce((total, payment) => {
                      return (
                        total +
                        payment.content.reduce(
                          (acc, item) =>
                            acc + item.quantity * item.pricingPrice,
                          0
                        )
                      );
                    }, 0)}{" "}
                  €
                </h2>
              </div>
            </article>
          </section>
          {booking.status === "canceled" ? (
            <section className="dashboardBookingItemButton">
              <button
                className="dashboardBookingItemButtonTotal"
                onClick={handleOpenBooking}
              >
                Rouvrir la réservation
              </button>
            </section>
          ) : booking.status === "paid" ? "" :(
            <section className="dashboardBookingItemButton">
              <button
                className="dashboardBookingItemButtonCancel"
                onClick={handleCancelBooking}
              >
                Annuler cette réservation
              </button>
              <button
                className="dashboardBookingItemButtonTotal"
                onClick={() => navigate(`/dashboard/booking/${id}/payment`)}
              >
                Finaliser la réservation
              </button>
            </section>
          )}
        </section>
      </main>
    );
  }
}
