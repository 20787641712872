import { Link } from "react-router-dom";

export default function Footer(){
    return (
        <section className="publicFooter">
          <div className="publicFooterContainer">
            <p>
              Développé par <Link to={"https://bryaan.fr?utm_source=cdfpomps"}>Bryaan</Link> avec ❤️ | © 2024 Tous droits
              réservés
            </p>

            <div className="publicFooterLinks">
              <Link to={"/cgu"}>Conditions générales d'Utilisation</Link>
              <Link to={"/privacy"}>Politique de confidentialité</Link>
              <Link to={"/legals"}>Mentions légales</Link>
            </div>
          </div>
        </section>
    )
}