import NavBar from "../../../../assets/components/Utils/NavBar";
import { Chip, TextField } from "@mui/material";
import { getBookings } from "../../../../helpers/api";
import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";

export default function BookingListScreen({ authUser }) {
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const [autoCompleteValue, setAutoCompleteValue] = useState([]);

  const handleSearch = (event, value) => {
    if (value) {
      setBookings(bookings.filter((booking) => booking.id === value.id));
    } else {
      getBookings().then((res) => {
        setBookings(res.data);
      });
    }
  };

  useEffect(() => {
    getBookings().then((res) => {
      setBookings(res.data);
      setAutoCompleteValue(
        res.data.map((booking) => ({ label: booking.name, id: booking.id }))
      );
    });
  }, []);

  return (
    <main>
      <NavBar authUser={authUser} />
      <section className="dashboardContainer">
        <section className="dashboardHeaderSearch">
          <div>
            <h1>Liste des réservations 🔥</h1>
            <p>Vous avez une liste de <span>{bookings.length} réservations</span> disponible :D</p>
          </div>
          <div className="dashboardHeaderSearchInput">
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={autoCompleteValue}
              sx={{ width: 300 }}
              onChange={handleSearch}
              renderInput={(params) => (
                <TextField {...params} label="Rechercher par nom" variant="standard" color="success" />
              )}
            />
          </div>
        </section>

        <section className="dashboardTable">
          {bookings.map((booking) => {
            return (
              <article key={booking.id} className="dashboardBookingItem" onClick={() => navigate(`/dashboard/booking/${booking.id}`)}>
                <div className="dashboardBookingItemContainer">
                  <h2>
                    {booking.user
                      ? `${booking.firstName} ${booking.lastName}`
                      : booking.name}
                  </h2>
                  <p>
                    {booking.content.map((content, index) => {
                      return (
                        <span key={content.pricingId}>
                          <Chip
                            label={`${content.quantity} ${content.pricingName}(s)`}
                            color="success"
                          />{" "}
                        </span>
                      );
                    })}{" "}
                    {booking.status === "paid" ? (
                      <Chip label={"Payé"} color="success" />
                    ) : booking.status === "partial_paid" ? (
                      <Chip label={"Partiel"} color="warning" />
                    ) : booking.status === "canceled" ? (
                      <Chip label={"Annulé"} color="error" />
                    ) : (
                      <Chip label={"Impayé"} color="error" />
                    )}
                  </p>
                </div>
              </article>
            );
          })}
        </section>
      </section>
    </main>
  );
}
