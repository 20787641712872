import { useEffect, useState } from "react";
import Swal from "sweetalert2";

export default function NameStep({
  stepUpdate,
  actualStep,
  reservation,
  setReservation,
  authUser,
}) {
  const [firstName, setFirstName] = useState(reservation.firstName);
  const [lastName, setLastName] = useState(reservation.lastName);
  const [email, setEmail] = useState(reservation.email);
  const [phone, setPhone] = useState(reservation.phone);

  const handleNextStep = () => {
    if (firstName && lastName && phone.length === 10 && email) {
      setReservation({
        ...reservation,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
      });

      stepUpdate(actualStep + 1);
    } else {
      Swal.fire({
        title: "Informations manquantes",
        text: "Veuillez remplir tous les champs pour continuer.",
        icon: "warning",
      });
    }
  };

  useEffect(() => {
    if (authUser) {
      stepUpdate(actualStep + 1);
    }
  }, [authUser]);

  return (
    <section className="publicForm">
      <div className="publicFormHead">
        <h2>Informations de contact</h2>
        <p>Veuillez renseigner les informations suivantes pour continuer.</p>
      </div>
      <div className="publicFormStepper">
        <h6>Entrez vos informations de contact pour continuer.</h6>
        <div className="publicFormInputs">
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Entrez votre prénom"
            required
          />
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Entrez votre nom"
            required
          />
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Entrez votre adresse email"
            required
          />
          <input
            type="tel"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Entrez votre numéro de téléphone"
            required
          />
        </div>
        <div className="publicFormButton">
          <button className="greenButton" onClick={() => handleNextStep()}>
            Continuer
          </button>
        </div>
      </div>
    </section>
  );
}
