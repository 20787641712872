import { useContext } from "react";
import PublicNavBar from "../../assets/components/Utils/PublicNavBar";
import AuthContext from "../../helpers/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import Footer from "../../assets/components/Utils/Footer";

export default function PrivacyScreen() {
  const { authUser } = useContext(AuthContext);
  const WEBSITE_NAME = "RESERVATION-POMPS.LESFETES.ORG";
  const WEBSITE_RESPONSABLE = "Association Comité des Fêtes de Pomps";

  return (
    <div className="publicSection">
      <section className="publicSectionContainer">
        <PublicNavBar authUser={authUser} />

        <section className="publicHeader">
          <div>
            <h1>Politique de Confidentialité</h1>
          </div>
        </section>
        <section>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> QUELLES DONNÉES
            COLLECTONS-NOUS ?
          </h2>
          <p>
            {WEBSITE_NAME} collecte les données suivantes :<br />
            - Votre nom et prénom
            <br />
            - Votre adresse mail
            <br />
            - Votre numéro de téléphone
            <br />
            - Votre commune de résidence
            <br />
            - Votre localisation
            <br />
            - Votre comportement sur le site
            <br />
            - Votre identifiant de session
            <br />
            - Votre identifiant de cookie
            <br />
            - Votre identifiant de compte
            <br />
            - Votre identifiant de transaction
            <br />
            - Votre identifiant de commande
            <br />
            - Votre identifiant de session
            <br />
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> COMMENT
            COLLECTONS-NOUS VOS DONNÉES ?
          </h2>
          <p>
            Nous collectons vos données lorsque vous remplissez un formulaire
            d'inscription, de réservation sur notre site, de modification du mot
            de passe et toutes modifications de réservations ou du compte.{" "}
            <br />
            Nous collectons également vos données lorsque vous interagissez avec
            un membre du comité des fêtes de Pomps ou un administrateur du site
            dès lors qu'il s'agit d'un suivi de votre réservation ou d'une
            demande explicite de votre part concernant vos données. <br />
            Nous collectons également vos données lorsque vous interagissez avec
            notre site via les cookies.
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> COMMENT UTILISONS-NOUS
            VOS DONNÉES ?
          </h2>
          <p>
            {WEBSITE_NAME} utilise vos données pour les finalités suivantes :{" "}
            <br />
            - Gestion des réservations
            <br />
            - Gestion des comptes utilisateurs
            <br />
            - Gestion des paiements
            <br />
            - Gestion des événements
            <br />
            - Gestion des statistiques
            <br />
            - Gestion des cookies
            <br />
            - Gestion des demandes de modification de données
            <br />
            - Gestion des demandes de suppression de données
            <br />
            - Gestion des demandes de modification de mot de passe
            <br />
            - Gestion des demandes de modification de réservation
            <br />
            - Gestion des demandes de modification de compte
            <br />
            <br />
            {WEBSITE_NAME} ne commercialise pas vos données personnelles qui
            sont donc uniquement utilisées par nécessité ou à des fins
            statistiques et d'analyses.
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> COMMENT STOCKONS-NOUS
            VOS DONNÉES ?
          </h2>
          <p>
            {WEBSITE_NAME} stocke vos données dans les serveurs de la société
            Amazon Web Services Inc. dans les serveurs (eu-west-3) de Paris,
            France. <br />
            Vos données sont stockées de manière sécurisée et cryptée. <br />
            Vos données sont stockées pour une durée de 5 ans ou jusqu'à votre
            demande explicite de suppression de données.
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> COOKIES
          </h2>
          <p>
            Les cookies sont des fichiers texte placés sur votre ordinateur pour
            collecter des informations de connexion Internet standard et des
            informations sur le comportement des visiteurs. Lorsque vous visitez
            notre site Web, nous pouvons collecter automatiquement des
            informations auprès de vous via des cookies ou une technologie
            similaire. Pour plus d'informations, visitez
            https://allaboutcookies.org.
            <br />
            <br />
            {WEBSITE_NAME} utilise des cookies pour stocker vos préférences et
            vos données de session. <br />
            Vous pouvez désactiver les cookies dans les paramètres de votre
            navigateur.
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> QUELS SONT VOS DROITS
            ?
          </h2>
          <p>
            Vous avez le droit de demander l'accès à vos données, la
            rectification de vos données, la suppression de vos données, la
            limitation du traitement de vos données, la portabilité de vos
            données et de vous opposer au traitement de vos données. <br />
            Vous pouvez exercer ces droits en contactant l'{
              WEBSITE_RESPONSABLE
            }{" "}
            en envoyant un mail à l'adresse suivante :
            comitedesfetespomps@orange.fr <br />
            Vous avez également le droit d'introduire une réclamation auprès de
            la CNIL.
          </p>
          <h2>
            <FontAwesomeIcon icon={faAngleDoubleRight} /> MISE À JOUR DE LA
            POLITIQUE DE CONFIDENTIALITÉ
          </h2>
          <p>
            La présente politique de confidentialité peut être mise à jour à
            tout moment. <br />
            Nous vous recommandons de consulter régulièrement cette page pour
            vous tenir informé des changements apportés à la politique de
            confidentialité de {WEBSITE_NAME}. <br />
            <br />
            Date de dernière mise à jour : 01/09/2024
          </p>
        </section>
        <Footer />
      </section>
    </div>
  );
}
