import NavBar from "../../../../assets/components/Utils/NavBar";

export default function PaymentsScreen({ authUser }) {
    return (
        <main>
          <NavBar authUser={authUser} />
          <section className="dashboardContainer">
            <section className="dashboardHeader">
              <div>
                <h1>Bientôt <span>{authUser.first_name}</span> 🎯 !</h1>
                <p>Cette page n'a pas encore été publiée... Je bosse dessus !</p>
              </div>
            </section>
          </section>
        </main>
      );
  }